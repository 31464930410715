import "./../../Style/pages/Pages.scss";
import "./../../Style/pages/Component/UserProfile.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPenToSquare, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from "react-router-dom";
import ClientCar from "./Component/ClientCar";
import { useSelector } from "react-redux";
import ClientAPI from "../../api/ClientApi";
import { useEffect, useState } from "react";
import ClientModal from "../../components/Modals/ClientModal";

const SelectedClient = () => {
    const navigate = useNavigate();
    const { ClientId } = useParams();
    const { GetClientById } = ClientAPI();
    const [clientCarsData, setclientCarsData] = useState([]);
    const [getModalShow, setModalShow] = useState(false);
    useEffect(() => {
        GetClientById(ClientId).then((res) => {
            setclientCarsData(res?.data?.client)
        })
    }, [ClientId])
    return (
        <div className="bodyPage">
            <div className="topSection">
                <div className="text">Client Page</div>
                <div className="addNew" onClick={() => window.history.back()}><FontAwesomeIcon size='lg' icon={faArrowLeft} /></div>
            </div>

            <div className="UserProfileSection">
                <div className="EditBtn" onClick={() => { setModalShow(true) }}><FontAwesomeIcon size='lg' icon={faPenToSquare} /></div>
                <div className="imageProfile">
                    {clientCarsData?.image_url ? <img src={"/images/clients/" + clientCarsData?.image_url} alt="Logo" /> : <img src="/images/avatar.png" alt="Logo" />}
                </div>
                <div className="infoArea">
                    <div className="label">Name</div>
                    <div className="text">{clientCarsData?.fName} {clientCarsData?.lName}</div>
                    <div className="label">Username</div>
                    <div className="text">{clientCarsData?.username}</div>
                    <div className="label">Email</div>
                    <div className="text">{clientCarsData?.email}</div>
                    <div className="label">Phone</div>
                    <div className="text">{clientCarsData?.phoneNb}</div>
                    <div className="label">Address</div>
                    <div className="text">{clientCarsData?.address}</div>
                </div>
            </div>

            <ClientCar />

            <ClientModal
                show={getModalShow}
                onHide={() => setModalShow(false)}
                data={clientCarsData}
            />
        </div>
    )
}

export default SelectedClient