import { faLayerGroup } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const OwerServicesSection = () => {

    return (
        <div className="CardSection">
            <div className="cardHeader">
                <div className="iconTitle">
                    <div className="icons"><FontAwesomeIcon size="lg" icon={faLayerGroup} /></div>
                    <div className="title">Ower Services</div>
                </div>

                <div className="actionBar">
                    <button className="greenBtn">View</button>
                </div>
            </div>
            <div className="cardBody">

            </div>

            <div className="cardFooter">

            </div>
        </div>
    )

}

export default OwerServicesSection