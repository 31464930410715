import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./../../Style/components/ModalStyle.scss";
import { useState, useEffect } from 'react';
import CarAPI from '../../api/CarApi';
import Field from '../Render/Field';
import DropdownValue from '../Render/DropdownValue';
import ToggleBtn from '../Render/ToggleBtn';
import ImageUpload from '../Render/ImageUpload';
import { useSelector } from 'react-redux';

function CarModal(props) {
    const { UpdateCar } = CarAPI();
    const staticData = useSelector(state => state.storage.staticData);

    const [carData, setCarData] = useState({
        plate: props?.data?.plate || '',
        model: props?.data?.model || '',
        brand: props?.data?.brand || '',
        year: props?.data?.year || '',
        color: props?.data?.color || '',
        engine: props?.data?.engine || '',
        image_url: props?.data?.image_url || '',
        isShow: props?.data?.isShow || false,
    });

    const [imageFile, setImageFile] = useState(null);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (props?.data) {
            setCarData({
                ...props.data,
                image_url: props?.data?.image_url || '',
                isShow: props?.data?.isShow || false,
            });
        }
    }, [props?.data]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCarData({
            ...carData,
            [name]: value,
        });
    };

    const handleImageChange = (e) => {
        setImageFile(e.target.files[0]);
    };

    const validate = () => {
        let validationErrors = {};
        if (!carData.plate) validationErrors.plate = "Plate is required";
        if (!carData.model) validationErrors.model = "Model is required";
        if (!carData.brand) validationErrors.brand = "Brand is required";
        if (!carData.year) validationErrors.year = "Year is required";
        if (!carData.color) validationErrors.color = "Color is required";
        if (!carData.engine) validationErrors.engine = "Engine is required";

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate()) {
            const formData = new FormData();
            Object.keys(carData).forEach(key => {
                formData.append(key, carData[key]);
            });

            if (imageFile) {
                formData.append('image', imageFile);
            }

            try {
                const response = await UpdateCar(props?.data?.id, formData);
                if (response === 200) {
                    props.onHide();
                }
            } catch (error) {
                console.error("Update failed:", error);
            }
        } else {
            console.log("Validation failed");
        }
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <div className='HeaderTitle'>Edit Car</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="formsection">
                    <Field
                        id="plate"
                        label="Plate"
                        type="text"
                        name="plate"
                        value={carData.plate}
                        handleChange={handleChange}
                        error={errors.plate}
                        placeholder="Enter plate number"
                    />

                    <DropdownValue
                        id="brand"
                        label="Brand"
                        name="brand"
                        value={carData.brand}
                        handleChange={handleChange}
                        error={errors.brand}
                        placeholder={carData.brand}
                        options={staticData.carbrand}
                        type="carbrand"
                        isOther={false}
                    />

                    <DropdownValue
                        id="model"
                        label="Model"
                        name="model"
                        value={carData.model}
                        handleChange={handleChange}
                        error={errors.model}
                        placeholder={carData.model}
                        options={staticData.carmodel}
                        type="carmodel"
                        isOther={false}
                    />

                    <DropdownValue
                        id="year"
                        label="Year"
                        name="year"
                        value={carData.year}
                        handleChange={handleChange}
                        error={errors.year}
                        placeholder={carData.year}
                        options={staticData.caryear}
                        type="caryear"
                        isOther={false}
                    />

                    <DropdownValue
                        id="color"
                        label="Color"
                        name="color"
                        value={carData.color}
                        handleChange={handleChange}
                        error={errors.color}
                        placeholder={carData.color}
                        options={staticData.carcolor}
                        type="carcolor"
                        isOther={false}
                    />

                    <DropdownValue
                        id="engine"
                        label="Engine"
                        name="engine"
                        value={carData.engine}
                        handleChange={handleChange}
                        error={errors.engine}
                        placeholder={carData.engine}
                        options={staticData.carengine}
                        type="carengine"
                        isOther={false}
                    />

                    <ImageUpload
                        handleImageChange={handleImageChange}
                        error={errors.image}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" className="wrap" onClick={handleSubmit}>Edit</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default CarModal;
