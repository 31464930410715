import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { config } from "../../utils/Constants";
import AxiosRedux from "../../utils/AxiosRedux";
const URL = config.url;

const initialState = {
    isLoading: false,
    clientInfo: {
        FName: null,
        LName: null,
        Email: null,
        Phone: null,
        Address: null,
    },
    clientCars: null,
};

export const getclientCarsData = createAsyncThunk(
    'SelectedClientData/getclientCarsData',
    async ({ id }, { dispatch }) => {
        try {
            const response = await AxiosRedux(`${URL}car/user/${id}`, 'GET');

            if (response && response.status === 200) {
                const clientCarsData = response.data;

                // Dispatch the data to the appropriate slice based on the status
                dispatch(setclientCarsDataAction({ data: clientCarsData }));
                return clientCarsData;
            } else {
                toast.error('Something went wrong while Getting Clients Data');
                return false;
            }
        } catch (error) {
            toast.error('Something went wrong while Getting Clients Data');
            return false;
        }
    }
);

// Action to set Clients data based on status
const SelectedClientDataSlice = createSlice({
    name: "SelectedClientData",
    initialState,
    reducers: {
        settingClientStorageData: (state, action) => {
            const { field, value } = action.payload;
            console.log(field, value);
            state[field] = value;
          },
          settingNestedClientStorage: (state, action) => {
            const { parentField, field, value } = action.payload;
            state[parentField][field] = value;
          },

        setclientCarsDataAction: (state, action) => {
            const { data } = action.payload;
            state.clientCars = data;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getclientCarsData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(
                getclientCarsData.fulfilled,
                (state) => {
                    state.isLoading = false;
                }
            )
            .addCase(getclientCarsData.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

// Export the reducer and action with a different name
export const { setclientCarsDataAction , settingClientStorageData, settingNestedClientStorage } = SelectedClientDataSlice.actions;
export default SelectedClientDataSlice.reducer;
