import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { getclientCarsData } from "./../../../app/Slices/SelectedClientDataSlice"
import SimpleTable from "../../../components/BodyContent/SimpleTable";
import TableSkeleton from "../../../components/BodyContent/TableSkeleton";
import { useNavigate, useParams } from 'react-router-dom';
import { faArrowRight, faMinus, faPenToSquare, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NewCar from "./NewCar";
import CarModal from "../../../components/Modals/CarModal";

const ClientCar = () => {
    const { ClientId } = useParams();
    const clientCarsData = useSelector(state => state.selectedClientData);
    const [getDisplayedComponent, setDisplayedComponent] = useState("ClientCar");
    const [getModalShow, setModalShow] = useState(false);
    const [getSelectedCarData, setclientCarsData] = useState({});

    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(getclientCarsData({ id: ClientId }));
    }, [])

    const handleClientSelected = (id) => {
        navigate(`/client/${ClientId}/car/${id}`);
    }

    const columns = [
        { Header: '', accessor: 'image_url', Cell: ({ value }) => <img className="image" src={"/images/cars/" + value} alt="" /> },
        // { Header: 'ID', accessor: 'id', Cell: ({ value }) => <div>#{value}</div> },
        { Header: 'Brand', accessor: 'brand' },
        { Header: 'Model', accessor: 'model' },
        { Header: 'Year', accessor: 'year' },
        { Header: 'Color', accessor: 'color' },
        { Header: 'Engine', accessor: 'engine' },
        { Header: 'Plate', accessor: 'plate' },
        {
            Header: 'Action',
            accessor: 'action', // Use a unique accessor here
            Cell: ({ value, row }) => (
                <div className="actionBar">
                    <div className="EditBtn" onClick={() => { setModalShow(true); setclientCarsData(row.original) }}><FontAwesomeIcon size='lg' icon={faPenToSquare} /></div>
                    <button className="GreenBtn" onClick={() => { handleClientSelected(row.original.id) }}><FontAwesomeIcon size='lg' icon={faArrowRight} /></button>
                </div>
            )
        },
    ]



    return (
        <div className="ClientCar">
            <div className="topSection2">
                <div className="text">{getDisplayedComponent == "AddClientCar" ? "Add Car" : "Cars"}</div>
                <div className="addNew" onClick={() => setDisplayedComponent(getDisplayedComponent == "AddClientCar" ? "ClientCar" : "AddClientCar")}><FontAwesomeIcon size='lg' icon={getDisplayedComponent == "AddClientCar" ? faMinus : faPlus} /></div>
            </div>
            {getDisplayedComponent == "AddClientCar" ?
                <NewCar clientId={ClientId} setDisplayedComponent={setDisplayedComponent} /> :
                <>
                    {clientCarsData?.clientCars?.cars == null ?
                        <TableSkeleton />
                        :
                        <SimpleTable data={clientCarsData?.clientCars?.cars} columns={columns} totalPages={1} currentPage={1} handleNextPage={() => { }} handlePrevPage={() => { }} />
                    }
                </>
            }
            <CarModal
                show={getModalShow}
                onHide={() => setModalShow(false)}
                data={getSelectedCarData}
            />
        </div>
    )
}

export default ClientCar