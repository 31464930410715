import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { config } from "./../../utils/Constants";
import AxiosRedux from "./../../utils/AxiosRedux";
const URL = config.url;

const initialState = {
    isLoading: false,
    categoryData: null,         // Category Data
    categoryItemsData: null,    // Category Items Data
};

// Fetch menu categories
export const getmenuCategorySlice = createAsyncThunk(
    'menuSlice/getmenuCategorySlice',
    async (_, { dispatch }) => {
        try {
            const response = await AxiosRedux(`${URL}/menu-categories`, 'GET');

            if (response && response.status === 200) {
                const categoryData = response.data;
                dispatch(setmenuCategorySliceAction({ data: categoryData }));
                return categoryData;
            } else {
                toast.error('Something went wrong while getting menu categories.');
                return false;
            }
        } catch (error) {
            toast.error('Something went wrong while getting menu categories.');
            return false;
        }
    }
);

// Fetch menu category items
export const getmenuCategoryItemSlice = createAsyncThunk(
    'menuSlice/getmenuCategoryItemSlice',
    async ({ categoryId }, { dispatch }) => {
        try {
            const response = await AxiosRedux(`${URL}menu-items/category/${categoryId}`, 'GET');

            if (response && response.status === 200) {
                const categoryItemsData = response.data;
                dispatch(setmenuCategoryItemSliceAction({ data: categoryItemsData }));
                return categoryItemsData;
            } else {
                toast.error('Something went wrong while getting menu items.');
                return false;
            }
        } catch (error) {
            toast.error('Something went wrong while getting menu items.');
            return false;
        }
    }
);

// Create the slice
const MenuSlice = createSlice({
    name: "menuSlice",
    initialState,
    reducers: {
        setmenuCategorySliceAction: (state, action) => {
            const { data } = action.payload;
            state.categoryData = data;
        },
        setmenuCategoryItemSliceAction: (state, action) => {
            const { data } = action.payload;
            state.categoryItemsData = data;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getmenuCategorySlice.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getmenuCategorySlice.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(getmenuCategorySlice.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getmenuCategoryItemSlice.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getmenuCategoryItemSlice.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(getmenuCategoryItemSlice.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

// Export actions and reducer
export const { setmenuCategorySliceAction, setmenuCategoryItemSliceAction } = MenuSlice.actions;
export default MenuSlice.reducer;
