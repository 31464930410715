import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { config } from "../../utils/Constants";
import AxiosRedux from "../../utils/AxiosRedux";
const URL = config.url;

const initialState = {
    isLoading: false,
    repairTask: null,
};

export const getrepairTaskData = createAsyncThunk(
    'SelectedRepairData/getrepairTaskData',
    async ({ id }, { dispatch }) => {
        try {
            const response = await AxiosRedux(`${URL}task/repair/${id}`, 'GET');

            if (response && response.status === 200) {
                const repairTaskData = response.data;

                // Dispatch the data to the appropriate slice based on the status
                dispatch(setrepairTaskDataAction({ data: repairTaskData }));
                return repairTaskData;
            } else {
                toast.error('Something went wrong while Getting Clients Data');
                return false;
            }
        } catch (error) {
            toast.error('Something went wrong while Getting Clients Data');
            return false;
        }
    }
);

// Action to set Clients data based on status
const SelectedRepairDataSlice = createSlice({
    name: "SelectedRepairData",
    initialState,
    reducers: {
        setrepairTaskDataAction: (state, action) => {
            const { data } = action.payload;
            state.repairTask = data;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getrepairTaskData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(
                getrepairTaskData.fulfilled,
                (state) => {
                    state.isLoading = false;
                }
            )
            .addCase(getrepairTaskData.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

// Export the reducer and action with a different name
export const { setrepairTaskDataAction } = SelectedRepairDataSlice.actions;
export default SelectedRepairDataSlice.reducer;
