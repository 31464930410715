import React from "react";
import { Outlet } from "react-router-dom";
import UserHeader from "./BodyContent/UserHeader";
import "./../Style/components/UserOutlet.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import LoginAPI from "../api/LoginAPI";

function UserBodyContent() {
  const { Logout } = LoginAPI();

  return (
    <div className="UserOutletContainer">
      <UserHeader />

      <div className="UserBodyContent">
        <div className="UserBody">
          <Outlet />
        </div>
      </div>

      <div className="UserFooterContainer">
        <div className="UserFooter">
          <div className="text">All rights reserved <span>@Gorella Mechanix</span></div>
          <div className="Logout">
            <button className="LogoutBtn" onClick={() => { Logout() }}>
              <FontAwesomeIcon size="lg" icon={faPowerOff} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserBodyContent;
