import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import SimpleTable from "../../../components/BodyContent/SimpleTable";
import TableSkeleton from "../../../components/BodyContent/TableSkeleton";
import { useNavigate, useParams } from 'react-router-dom';
import { faArrowRight, faMinus, faPenToSquare, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NewTask from "./NewTask";
import { getrepairTaskData } from "../../../app/Slices/SelectedRepairDataSlice";
import TaskModal from "../../../components/Modals/TaskModal";

const RepairTask = () => {
    const { ClientId, CarId, RepairId } = useParams();
    const CaRepairsTaskData = useSelector(state => state.selectedRepairData);
    const [getDisplayedComponent, setDisplayedComponent] = useState("RepairTask");
    const dispatch = useDispatch();
    const [getSelectedCarData, setclientCarsData] = useState({});
    const [getModalShow, setModalShow] = useState(false);

    useEffect(() => {
        dispatch(getrepairTaskData({ id: RepairId }));
    }, [])


    const columns = [
        { Header: 'ID', accessor: 'id', Cell: ({ value }) => <div>#{value}</div> },
        { Header: 'Issue', accessor: 'issue' },
        { Header: 'Items', accessor: 'items' },
        { Header: 'Note', accessor: 'note' },
        { Header: 'Price', accessor: 'price', Cell: ({ value }) => <div>${value}</div> },
        {
            Header: 'Action',
            accessor: 'action',
            Cell: ({ value, row }) => (
                <div className="actionBar">
                    <div className="EditBtn" onClick={() => { setModalShow(true); setclientCarsData(row.original) }}><FontAwesomeIcon size='lg' icon={faPenToSquare} /></div>
                </div>
            )
        },
    ]

    return (
        <div className="ClientCar">
            <div className="topSection2">
                <div className="text">{getDisplayedComponent == "AddRepairTask" ? "Add Task" : "Tasks"}</div>
                <div className="addNew" onClick={() => setDisplayedComponent(getDisplayedComponent == "AddRepairTask" ? "RepairTask" : "AddRepairTask")}><FontAwesomeIcon size='lg' icon={getDisplayedComponent == "AddRepairTask" ? faMinus : faPlus} /></div>
            </div>
            {getDisplayedComponent == "AddRepairTask" ?
                <NewTask repairId={RepairId} setDisplayedComponent={setDisplayedComponent} /> :
                <>
                    {CaRepairsTaskData?.repairTask?.tasks == null ?
                        <TableSkeleton />
                        :
                        <SimpleTable data={CaRepairsTaskData?.repairTask?.tasks} columns={columns} totalPages={1} currentPage={1} handleNextPage={() => { }} handlePrevPage={() => { }} />
                    }
                </>
            }

            <TaskModal
                show={getModalShow}
                onHide={() => setModalShow(false)}
                data={getSelectedCarData}
            />

        </div>
    )
}

export default RepairTask