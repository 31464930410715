import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faFileInvoice, faPenToSquare, faPoo, faRightFromBracket, faTruckFast, faUser, faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { settingStorageData } from '../../app/Slices/StorageSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import LoginAPI from '../../api/LoginAPI';

const Navbar = () => {
    const { Logout } = LoginAPI();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const data = useSelector((state) => state.storage.activeTab);

    const navItems = [
        { id: 'todo', text: 'Todo', icon: faPenToSquare },
        { id: 'client', text: 'Clients', icon: faUser },
        { id: 'menu', text: 'Menu', icon: faFileInvoice },
        { id: 'controlPanel', text: 'Control Panel', icon: faWandMagicSparkles },
    ];

    const [activeTab, setActiveTab] = useState(() => {
        // Extract the first path segment from the URL
        const firstPath = location.pathname.split('/')[1];
        return data || firstPath || navItems[0].id;
    });

    useEffect(() => {
        dispatch(settingStorageData({ field: 'activeTab', value: activeTab }));
    }, [activeTab, dispatch]);

    const handleTabClick = (id) => {
        dispatch(settingStorageData({ field: 'isDrawerOpen', value: false }));
        navigate(`/${id}`);
        setActiveTab(id);
    };


    return (
        <div className="navbar">
            <div className="logo_img" onClick={() => { dispatch(settingStorageData({ field: 'isNavbarOpen', value: false })) }}>
                <img src="/images/logo/blackIcon.png" alt="Logo" />
            </div>
            <div className="navbarBody">
                <div className="navTabs">
                    {navItems.map((item) => (
                        <div
                            key={item.id}
                            className={`navTab ${activeTab === item.id ? 'active' : ''}`}
                            onClick={() => handleTabClick(item.id)}
                        >
                            <div className="TabImage">
                                <FontAwesomeIcon size='lg' className="icon" icon={item.icon} />
                            </div>
                            <div className="TabText">
                                <div className='text'>{item.text}</div>
                            </div>
                        </div>
                    ))}
                </div>
                <div
                    className="navTab"
                    onClick={() => { Logout() }}
                >
                    <div className="TabImage">
                        <FontAwesomeIcon size='lg' className="icon" icon={faRightFromBracket} />
                    </div>
                    <div className="TabText">
                        <div className='text'>Logout</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Navbar;
