import { useState } from 'react';
import "./../../../Style/pages/Form.scss";
import "./../../../Style/pages/Pages.scss";
import { useDispatch } from 'react-redux';
import MenuApi from '../../../api/MenuApi';
import { getmenuCategoryItemSlice } from '../../../app/Slices/MenuSlice';
import Field from '../../../components/Render/Field';
import ToggleBtn from '../../../components/Render/ToggleBtn';

const NewItem = ({ clientId, setDisplayedComponent }) => {
    const { CreateItem } = MenuApi();
    const dispatch = useDispatch();
    const [clientData, setClientData] = useState({
        title: "",
        description: "",
        price: "",
        isShow: "",
        priority: "",
        category_id: "",
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setClientData({
            ...clientData,
            [name]: value
        });
    };

    const validate = () => {
        let validationErrors = {};
        if (!clientData.title) validationErrors.title = "Title is required";
        if (!clientData.description) validationErrors.description = "Description is required";
        if (!clientData.price) validationErrors.price = "Price is required";
        if (!clientData.isShow) validationErrors.isShow = "IsShow is required";
        if (!clientData.priority) validationErrors.priority = "Priority is required";

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate()) {
            CreateItem({
                title: clientData.title,
                description: clientData.description,
                price: clientData.price,
                isShow: clientData.isShow,
                priority: clientData.priority,
                category_id: clientId,
            }).then((response) => {
                if (response === 201) {
                    dispatch(getmenuCategoryItemSlice({ categoryId: clientId }));
                    setDisplayedComponent("CategoryItem");
                }
            });

        } else {
            console.log("Validation failed");
        }
    };

    return (
        <div className="formsection">
            <h1
                className={
                    Object.keys(errors).length > 0 &&
                        (errors.title || errors.description || errors.price || errors.isShow || errors.priority)
                        ? "danger"
                        : ""
                }>New Item Form</h1>
            <Field
                id="title"
                label="Title"
                type="text"
                name="title"
                value={clientData.title}
                handleChange={handleChange}
                error={errors.title}
                placeholder="Title"
            />

            <Field
                id="description"
                name="description"
                type="text"
                value={clientData.description}
                handleChange={handleChange}
                placeholder="Description"
                label="Description"
                error={errors.description}
            />

            <Field
                id="price"
                name="price"
                type="number"
                value={clientData.price}
                handleChange={handleChange}
                placeholder="Price"
                label="Price"
                error={errors.price}
            />

            <ToggleBtn
                isShow={clientData.isShow}
                toggleIsShow={(e) => setClientData({ ...clientData, isShow: e.target.checked })}
            />

            <Field
                id="priority"
                name="priority"
                type="number"
                value={clientData.priority}
                handleChange={handleChange}
                placeholder="Priority"
                label="Priority"
                error={errors.priority}
            />

            <div className="wrap">
                <button onClick={handleSubmit}>
                    Submit
                </button>
            </div>
        </div>
    );
};

export default NewItem;
