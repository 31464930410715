// Production URL
const production = {
  url: "https://autotech-lb.com/backend/api/",
  userUrl: "https://autotech-lb.com/backend/api/userSide/",
};

// Development URL
const development = {
  url: "http://localhost:3000/api/",
  userUrl: "http://localhost:3000/api/userSide/",
};

export const isDevMode = process.env.NODE_ENV === "development";

export const config = isDevMode ? development : production;
