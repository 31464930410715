import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./../../../Style/SubComp/SocialMediaSection.scss";

const SocialMedia = () => {
    return (
        <div className="CardSection">
            <div className="cardHeader">
                <div className="iconTitle">
                    <div className="icons"><FontAwesomeIcon size="lg" icon={faGlobe} /></div>
                    <div className="title">Social Media</div>
                </div>
            </div>
            <div className="cardBody">
                <div className="contactInfo">
                    <a className="contactItem" href="https://wa.me/96179143921" target="_blank" rel="noopener noreferrer">
                        <div className="icons"><img src="/images/logo/whatsapp.png" alt="WhatsApp" /></div>
                        <div className="title">Chat with us on WhatsApp</div>
                    </a>
                    <a className="contactItem" href="tel:+96179143921">
                        <div className="icons"><img src="/images/logo/phone.png" alt="Phone" /></div>
                        <div className="title">Call us</div>
                    </a>
                    <a className="contactItem" href="mailto:elie.yammouny@hotmail.com">
                        <div className="icons"><img src="/images/logo/email.png" alt="Email" /></div>
                        <div className="title">Email us</div>
                    </a>
                    <a className="contactItem" href="https://instagram.com/gorillamechanix" target="_blank" rel="noopener noreferrer">
                        <div className="icons"><img src="/images/logo/instagram.png" alt="Instagram" /></div>
                        <div className="title">Follow us on Instagram</div>
                    </a>
                </div>
            </div>
            <div className="cardFooter">
                {/* Footer content if needed */}
            </div>
        </div>
    );
};

export default SocialMedia;
