import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./../../Style/components/ModalStyle.scss";
import { useState, useEffect } from 'react';
import Field from '../Render/Field';
import StaticDataAPI from '../../api/StaticDataApi';

function UpdateStaticDataModal(props) {
    const { UpdateStatic } = StaticDataAPI();

    const [clientData, setClientData] = useState({
        name: props?.data?.name,
        type: props?.data?.type,
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (props?.data) {
            setClientData({
                name: props?.data?.name,
                type: props?.data?.type,
            });
        }
    }, [props?.data]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setClientData({
            ...clientData,
            [name]: value
        });
    };

    const validate = () => {
        let validationErrors = {};
        if (!clientData.name) validationErrors.name = "Name is required";

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate()) {
            UpdateStatic(props?.data?.uuid, clientData).then((response) => {
                if (response === 200) {
                    props.onHide();
                }
            });

        } else {
            console.log("Validation failed");
        }
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <div className='HeaderTitle'>
                        Edit Static Data
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="formsection">

                    <Field
                        id="name"
                        label="Name"
                        type="text"
                        name="name"
                        value={clientData.name}
                        handleChange={handleChange}
                        error={errors.name}
                        placeholder="Enter name"
                    />

                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" className="wrap" onClick={handleSubmit}>Edit</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default UpdateStaticDataModal;