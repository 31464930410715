import { toast } from "react-toastify";
import useInterceptorAxios from "../utils/axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { settingUserStorageData } from "../app/Slices/UserStoarageSlices";

const LoginAPI = () => {
  const Client = useInterceptorAxios();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const Register = async (registrationData) => {
    try {
      const response = await Client.post(`/register`, {
        username: registrationData?.username,
        password: registrationData?.password,
      });
      if (response?.status === 201) {
        toast.success(response?.data?.message);
      }

      return response?.status;
    } catch (e) {
      toast.error("Something went wrong while Registering !!");
    }
  };

  const Login = async ({ registrationData }) => {
    try {
      const response = await Client.post(`/login`, {
        username: registrationData?.username,
        password: registrationData?.password,
      });
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        sessionStorage.setItem("Token", response?.data?.token);
        if (response?.data?.role == "admin") {
          navigate("/todo");
        } else if (response?.data?.role == "user") {
          sessionStorage.setItem("username", response?.data?.username);
          sessionStorage.setItem("clientId", response?.data?.clientId);
          dispatch(settingUserStorageData({ field: "username", value: response?.data?.username }));
          navigate("/userProfile/home");
        } else {
          navigate("/not-authorized");
        }
      }
    } catch (e) {
      toast.error("Something went wrong while Logging in !!");
    }
  };

  const Logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
  };

  return {
    Register,
    Login,
    Logout,
  };
};

export default LoginAPI;
