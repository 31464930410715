import { toast } from "react-toastify";
import useInterceptorAxios from "../utils/axios";
import { useNavigate, useParams } from "react-router-dom";
import { getStaticDataSlice } from "../app/Slices/StorageSlice";
import { useDispatch } from "react-redux";

const StaticDataAPI = () => {
    const Client = useInterceptorAxios();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const CreateStatic = async (newStaticData) => {
        try {
            const response = await Client.post(`/static`, {
                name: newStaticData?.name,
                type: newStaticData?.type,
            });
            if (response?.status === 201) {
                toast.success(response?.data?.message);
                dispatch(getStaticDataSlice());
            }

            return response?.status;
        } catch (e) {
            toast.error("Something went wrong while Creating New Static data!!");
        }
    };

    const UpdateStatic = async (id, newStaticData) => {
        try {
            const response = await Client.put(`/static/${id}`, {
                name: newStaticData?.name,
                type: newStaticData?.type,
            });
            if (response?.status === 200) {
                toast.success(response?.data?.message);
                dispatch(getStaticDataSlice());
            }

            return response?.status;
        } catch (e) {
            toast.error("Something went wrong while Updating Static data !!");
        }
    };


    return {
        CreateStatic,
        UpdateStatic
    };
};

export default StaticDataAPI;
