import { toast } from "react-toastify";
import useInterceptorAxios from "../utils/axios";
import { useNavigate, useParams } from "react-router-dom";

const RepairAPI = () => {
    const Client = useInterceptorAxios();
    const navigate = useNavigate();
    const { CarId } = useParams();

    const CreateRepair = async (newRepairData) => {
        try {
            const response = await Client.post(`/repair`, {
                handCost: newRepairData?.handCost,
                note: newRepairData?.note,
                car_id: newRepairData?.car_id,
            });
            if (response?.status === 201) {
                toast.success(response?.data?.message);
            }

            return response?.status;
        } catch (e) {
            toast.error("Something went wrong while Creating New Car Repair!!");
        }
    };

    const GetRepairById = async (id) => {
        try {
            const response = await Client.get(`/repair/${id}`);
            return response;
        } catch (e) {
            toast.error("Something went wrong while Getting Repair info !!");
        }
    };

    const UpdateRepair = async (id, newRepairData) => {
        try {
            const response = await Client.put(`/repair/${id}`, {
                handCost: newRepairData?.handCost,
                note: newRepairData?.note,
                car_id: CarId,
            });
            if (response?.status === 200) {
                toast.success(response?.data?.message);
                navigate(0);
            }

            return response?.status;
        } catch (e) {
            toast.error("Something went wrong while Updating Car Repair!!");
        }
    };


    return {
        CreateRepair,
        GetRepairById,
        UpdateRepair
    };
};

export default RepairAPI;
