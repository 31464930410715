import "./../../Style/pages/Pages.scss";
import "./../../Style/pages/Component/UserProfile.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPenToSquare, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import CarAPI from "../../api/CarApi";
import CarRepair from "./Component/CarRepair";
import CarModal from "../../components/Modals/CarModal";

const SelectedClientCar = () => {
    const { ClientId, CarId } = useParams();
    const { GetCarById } = CarAPI();
    const [clientCarsData, setclientCarsData] = useState([]);
    const [getModalShow, setModalShow] = useState(false);
    useEffect(() => {
        GetCarById(CarId).then((res) => {
            setclientCarsData(res?.data?.car)
        })
    }, [CarId])
    return (
        <div className="bodyPage">
            <div className="topSection">
                <div className="text">Client Car Page</div>
                <div className="addNew" onClick={() => window.history.back()}><FontAwesomeIcon size='lg' icon={faArrowLeft} /></div>
            </div>

            <div className="UserProfileSection">
                <div className="EditBtn" onClick={() => { setModalShow(true) }}><FontAwesomeIcon size='lg' icon={faPenToSquare} /></div>
                <div className="imageProfile">
                    {clientCarsData?.image_url ? <img src={"/images/cars/" + clientCarsData?.image_url} alt="Logo" /> : <img src="/images/avatarCar.png" alt="Logo" />}
                    {/* <img src="/images/avatarCar.png" alt="Logo" /> */}
                </div>
                <div className="infoArea">
                    <div className="label">Plate</div>
                    <div className="text">{clientCarsData?.plate}</div>
                    <div className="label">Model</div>
                    <div className="text">{clientCarsData?.model}</div>
                    <div className="label">Brand</div>
                    <div className="text">{clientCarsData?.brand}</div>
                    <div className="label">Year</div>
                    <div className="text">{clientCarsData?.year}</div>
                    <div className="label">Color</div>
                    <div className="text">{clientCarsData?.color}</div>
                    <div className="label">Engine</div>
                    <div className="text">{clientCarsData?.engine}</div>
                </div>
            </div>

            <CarRepair />

            <CarModal
                show={getModalShow}
                onHide={() => setModalShow(false)}
                data={clientCarsData}
            />

        </div>
    )
}

export default SelectedClientCar