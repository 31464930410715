import React, { useState, useEffect } from "react";
import NewStaticDataModal from "../Modals/NewStaticDataModal";
import "./../../Style/components/Dropdown.scss";

const DropdownValue = ({
    id,
    label,
    name,
    value,
    handleChange,
    error,
    placeholder,
    options,
    type,
    isOther,
}) => {
    const [modalShow, setModalShow] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState(options || []);
    const [searchTerm, setSearchTerm] = useState("");
    const [sortOrder, setSortOrder] = useState("asc"); // Default sorting order

    // Sync filteredOptions with options when options prop changes
    useEffect(() => {
        setFilteredOptions(sortOptions(options || [], sortOrder));
    }, [options, sortOrder]);

    const getDisplayValue = () => {
        const selectedOption = options.find((option) => option.uuid === value);
        return selectedOption ? selectedOption.name : placeholder;
    };

    const handleSelectChange = (option) => {
        if (option === "other") {
            setModalShow(true);
        } else {
            handleChange({ target: { name, value: option.uuid || option } });
        }
        setIsOpen(false);
    };

    const handleModalSubmit = (customValue) => {
        handleChange({ target: { name, value: customValue } });
        setModalShow(false);
    };

    const handleSearchChange = (e) => {
        const searchValue = e.target.value;
        setSearchTerm(searchValue);
        setFilteredOptions(
            sortOptions(
                options?.filter((option) =>
                    (option.name || option)
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
                ),
                sortOrder
            )
        );
    };

    const sortOptions = (options, order) => {
        return [...options].sort((a, b) => {
            const nameA = (a.name || a).toLowerCase();
            const nameB = (b.name || b).toLowerCase();
            if (nameA < nameB) return order === "asc" ? -1 : 1;
            if (nameA > nameB) return order === "asc" ? 1 : -1;
            return 0;
        });
    };

    const toggleSortOrder = () => {
        setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    };

    return (
        <div className="custom-dropdown">
            <label htmlFor={id}>{label}</label>
            <div className={`dropdown-header ${isOpen ? "open" : ""}`}>
                <div
                    className="selected-value"
                    onClick={() => setIsOpen((prev) => !prev)}
                >
                    {getDisplayValue()}
                </div>
                <div className="dropdown-arrow" />
            </div>
            {isOpen && (
                <div className="dropdown-body">
                    <input
                        type="text"
                        className="search-box"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                    <button
                        className="sort-button"
                        onClick={toggleSortOrder}
                    >
                        Sort: {sortOrder === "asc" ? "Ascending" : "Descending"}
                    </button>
                    <div className="options-container">
                        {!isOther && (
                            <div
                                className="option other"
                                onClick={() => handleSelectChange("other")}
                            >
                                Other
                            </div>
                        )}
                        <ul className="options-list">
                            {filteredOptions.map((option) => (
                                <li
                                    key={option.uuid || option}
                                    className="option"
                                    onClick={() => handleSelectChange(option)}
                                >
                                    {option.name || option}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
            {error && <span className="error">{error}</span>}

            <NewStaticDataModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                onSubmit={handleModalSubmit}
                data={{ name: "", type: type }}
            />
        </div>
    );
};

export default DropdownValue;
