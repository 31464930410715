import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getclientCarsData } from '../../app/Slices/SelectedClientDataSlice';
import './../../Style/pages/UserDashboardCars.scss';

const UserDashboard = () => {
    const dispatch = useDispatch();
    const ClientId = sessionStorage.getItem('clientId');

    const userCars = useSelector(state => state.selectedClientData.clientCars || []); // Default to an empty array

    useEffect(() => {
        if (ClientId) {
            dispatch(getclientCarsData({ id: ClientId }));
        }
    }, [dispatch, ClientId]);

    return (
        <div className="dashboard-container">
            <h1 className="dashboard-title">User Dashboard</h1>
            {userCars?.cars?.length > 0 ? (
                <div className="cars-grid">
                    {userCars?.cars.map(car => (
                        <div key={car.id} className="car-card">
                            <img
                                src={'/images/cars/' + car.image_url || '/images/avatarCar.png'}
                                alt={car.model}
                                className="car-image"
                                onError={(e) => {
                                    e.target.src = '/images/avatarCar.png'; // Fallback for error
                                    e.target.onerror = null; // Prevent infinite onError loops in older browsers
                                }}
                            />

                            <div className="car-card-header">
                                <h2 className="car-model">{car.model}</h2>
                                <span className="car-plate">{car.plate}</span>
                            </div>
                            <div className="car-card-body">
                                <p className="car-detail"><strong>Brand:</strong> {car.brand}</p>
                                <p className="car-detail"><strong>Year:</strong> {car.year}</p>
                                <p className="car-detail"><strong>Color:</strong> {car.color}</p>
                                <p className="car-detail"><strong>Engine:</strong> {car.engine}</p>
                            </div>
                        </div>
                    ))}

                </div>
            ) : (
                <p className="no-data-message">No cars found for this client.</p>
            )}
        </div>
    );
};

export default UserDashboard;
