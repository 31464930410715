import { faFileInvoice, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getTopCategory, getTopCatItem } from "../../../app/Slices/FullMenuSlice";
import "./../../../Style/SubComp/MenuSection.scss";

const MenuSection = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const menuData = useSelector(state => state.fullMenuData);
    const [selectedCategory, setSelectedCategory] = useState(null);

    useEffect(() => {
        if (!menuData?.topCategory) {
            dispatch(getTopCategory());
        }
    }, [dispatch, menuData]);

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        dispatch(getTopCatItem(category.id)); // Fetch top items
    };

    return (
        <div className="CardSection">
            <div className="cardHeader">
                <div className="iconTitle">
                    <div className="icons"><FontAwesomeIcon size="lg" icon={faFileInvoice} /></div>
                    <div className="title">Menu</div>
                </div>

                <div className="actionBar">
                    <button className="greenBtn" onClick={() => navigate('/full-menu')}>View All Menu</button>
                </div>
            </div>

            <div className="cardBody">
                {!selectedCategory && Array.isArray(menuData?.topCategory?.categories) && menuData?.topCategory?.categories.length > 0 ? (
                    menuData.topCategory?.categories.map((category) => (
                        <div key={category.id} className="categoryCard" onClick={() => handleCategoryClick(category)}>
                            <img src={`/images/menu/${category.image_url}`} alt={category.title} className="categoryImage" />
                            <div className="Section">
                                <div className="categoryTitle">{category.title}</div>
                                <div className="categoryDescription">{category.description}</div>
                            </div>
                        </div>
                    ))
                ) : (!selectedCategory &&
                    <div>No categories available</div>
                )}

                {selectedCategory && (
                    <div className="itemSection">
                        <h2>{selectedCategory.title} <span> - Top 5 Items</span></h2>
                        <ul className="itemList">
                            {Array.isArray(menuData?.topCatItem?.items) && menuData?.topCatItem?.items.length > 0 ? (
                                menuData?.topCatItem?.items?.slice(0, 5).map(item => (
                                    <li key={item.id} className="itemCard">
                                        <div className="Sec">
                                            <div className="itemTitle">{item.title}</div>
                                            <div className="itemDescription">{item.description}</div>
                                        </div>
                                        <div className="itemPrice">${item.price}</div>
                                    </li>
                                ))
                            ) : (
                                <div>No items available</div>
                            )}
                        </ul>
                        <button className="blueBtn" onClick={() => setSelectedCategory(null)}>Go Back</button>
                    </div>
                )}
            </div>

            <div className="cardFooter">
                <div className="caption">
                    Explore Menu
                </div>
            </div>
        </div>
    );
};

export default MenuSection;
