import axios from 'axios';
import { toast } from 'react-toastify';
// import jwtDecode from 'jwt-decode';
import { config } from './Constants';

const URL = config.url;

const AxiosRedux = async (url, method, body = null) => {
    try {
        const getToken = sessionStorage.getItem('Token');
        // if (getToken) {
        //     try {
        //         // const decodedToken = jwtDecode(getToken);
        //         const currentTimeInSeconds = Math.floor(Date.now() / 1000);
        //         const tokenExpiration = decodedToken.exp;

        //         if (tokenExpiration > currentTimeInSeconds) {
        //             // Token is not expired, proceed with the request
        //         } 
        //     } catch (error) {
        //         console.error('Error decoding token:', error);
        //     }
        // } else {
        //     console.log('Token not found');
        // }


        const headers = {
            Authorization: `${getToken}`,
            'Content-Type': body instanceof FormData ? 'multipart/form-data' : 'application/json',
        };

        const config = { headers };

        const response =
            method === 'GET'
                ? await axios.get(url, config)
                : await axios.request({ url, method, data: body, baseURL: URL, ...config });

        return response;
    } catch (error) {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            sessionStorage.clear();
            localStorage.clear();
            window.location.href = '/';
        } else {
            toast.error('Something went wrong while making the request');
            console.error(error);
        }

        return null;
    }
};

export default AxiosRedux;