import { faDashboard, faHome, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const navbarData = [
    { path: "/userProfile/home", name: "Home", icon: faHome },
    { path: "/userProfile/dashboard", name: "Dashboard", icon: faDashboard },
    { path: "/userProfile/contact", name: "Contact", icon: faPhone }
];

const UserHeader = () => {
    const location = useLocation(); // To get the current URL path
    const navigate = useNavigate();

    return (
        <div className="UserHeader">
            <div className="subHeader">
                <div className="logo_img">
                    <img src="/images/logo/blackIcon.png" alt="Logo" />
                </div>

                <div className="NavbarLinks">
                    {navbarData.map((item, index) => (
                        <div
                            key={index}
                            className={`NavbarLink ${location.pathname === item.path ? "NavbarLinkActive" : ""}`}
                            onClick={() => navigate(item.path)}
                        >
                            <div className="logo">
                                <FontAwesomeIcon size="lg" icon={item.icon} />
                            </div>
                            <div className="text">{item.name}</div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="bottomSection">
                <div className="text">Gorella Mechanix</div>
                <div className="text">{new Date().toLocaleDateString()}</div>
            </div>
        </div>
    );
};

export default UserHeader;
