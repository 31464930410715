import { createSlice } from "@reduxjs/toolkit";
import { config } from "../../utils/Constants";
import { toast } from 'react-toastify';
import AxiosRedux from "../../utils/AxiosRedux";
import { createAsyncThunk } from "@reduxjs/toolkit";

const URL = config.url;

const initialState = {
  username: null,
  UserData: {},
  userRate: null
};

// Thunk to fetch user rate data
export const getUserRate = createAsyncThunk(
  'UserRateData/getUserRate',
  async (username, { rejectWithValue }) => {
    try {
      const response = await AxiosRedux(`${URL}rate?username=${username}`, 'GET');
      if (response.status === 200) {
        return response.data;
      } else {
        toast.error('Error while fetching User Rate Data');
        return rejectWithValue('Error fetching user rate data');
      }
    } catch (error) {
      toast.error('Error while fetching User Rate Data');
      return rejectWithValue(error.message);
    }
  }
);


const UserStoarageSlices = createSlice({
  name: "Storage",
  initialState,
  reducers: {
    settingUserStorageData: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    settingUserNestedStorage: (state, action) => {
      const { parentField, field, value } = action.payload;
      state[parentField][field] = value;
    },
    resetUserStorageData: (state) => {
      state.username = null;
      state.UserData = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserRate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserRate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userRate = action.payload;
      })
      .addCase(getUserRate.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { settingUserStorageData, settingUserNestedStorage, resetUserStorageData } = UserStoarageSlices.actions;

export default UserStoarageSlices.reducer;
