import { faArrowLeft, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MenuApi from "../../api/MenuApi";
import CategoryMenuModal from "../../components/Modals/CategoryMenuModal";
import CategoryItem from "./Component/CategoryItem";


const CategoryMenu = () => {
    const navigate = useNavigate();
    const { CategoryId } = useParams();
    const { GetCategoryById } = MenuApi();
    const [getCategoryMenuData, setCategoryMenuData] = useState([]);
    const [getModalShow, setModalShow] = useState(false);
    useEffect(() => {
        GetCategoryById(CategoryId).then((res) => {
            setCategoryMenuData(res?.data?.category)
        })
    }, [CategoryId])
    return (
        <div className="bodyPage">
            <div className="topSection">
                <div className="text">Category Page</div>
                <div className="addNew" onClick={() => navigate('/menu')}><FontAwesomeIcon size='lg' icon={faArrowLeft} /></div>
            </div>

            <div className="UserProfileSection">
                <div className="EditBtn" onClick={() => { setModalShow(true) }}><FontAwesomeIcon size='lg' icon={faPenToSquare} /></div>
                <div className="imageProfile">
                    <img src={"/images/menu/" + getCategoryMenuData?.image_url} alt="Logo" />
                </div>
                <div className="infoArea">
                    <div className="label">Title</div>
                    <div className="text">{getCategoryMenuData?.title}</div>
                    <div className="label">Description</div>
                    <div className="text">{getCategoryMenuData?.description}</div>
                    <div className="label">Max Price</div>
                    <div className="text">{getCategoryMenuData?.max_price}</div>
                    <div className="label">Min Price</div>
                    <div className="text">{getCategoryMenuData?.min_price}</div>
                    <div className="label">Priority</div>
                    <div className="text">{getCategoryMenuData?.priority}</div>
                    <div className="label">Is Show</div>
                    <div className="text">{getCategoryMenuData?.isShow ? 'Yes' : 'No'}</div>
                </div>
            </div>

            <CategoryItem />

            <CategoryMenuModal
                show={getModalShow}
                onHide={() => setModalShow(false)}
                data={getCategoryMenuData}
            />
        </div>

    )
};

export default CategoryMenu