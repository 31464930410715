import { useState } from 'react';
import "./../../../Style/pages/Form.scss";
import "./../../../Style/pages/Pages.scss";
import MenuApi from '../../../api/MenuApi';
import { useNavigate } from 'react-router-dom';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ToggleBtn from '../../../components/Render/ToggleBtn';
import Field from '../../../components/Render/Field';
import ImageUpload from '../../../components/Render/ImageUpload';

const NewCategory = () => {
    const { CreateCategory } = MenuApi();
    const navigate = useNavigate();
    const [categoryData, setCategoryData] = useState({
        title: "",
        description: "",
        max_price: "",
        min_price: "",
        priority: "",
        isShow: true
    });

    const [image, setImage] = useState(null);
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCategoryData({
            ...categoryData,
            [name]: value
        });
    };

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    const validate = () => {
        let validationErrors = {};
        if (!categoryData.title) validationErrors.title = "Title is required";
        if (!categoryData.description) validationErrors.description = "Description is required";
        if (!image) validationErrors.image = "Image is required";
        if (!categoryData.max_price) validationErrors.max_price = "Max price is required";
        if (!categoryData.min_price) validationErrors.min_price = "Min price is required";
        if (!categoryData.priority) validationErrors.priority = "Priority is required";

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate()) {
            const formData = new FormData();
            formData.append("title", categoryData.title);
            formData.append("description", categoryData.description);
            formData.append("image", image);
            formData.append("max_price", categoryData.max_price);
            formData.append("min_price", categoryData.min_price);
            formData.append("priority", categoryData.priority);
            formData.append("isShow", categoryData.isShow);

            CreateCategory(formData).then((response) => {
                if (response === 201) {
                    navigate('/menu');
                }
            });
        } else {
            console.log("Validation failed");
        }
    };

    return (
        <div className="bodyPage">
            <div className="topSection">
                <div className="text">New Category Page</div>
                <div className="addNew" onClick={() => navigate('/menu')}>
                    <FontAwesomeIcon size='lg' icon={faMinus} />
                </div>
            </div>
            <div className="formsection">
                <h1 className={Object.keys(errors).length > 0 ? "danger" : ""}>
                    New Category Form
                </h1>

                <Field
                    id="title"
                    label="Title"
                    type="text"
                    name="title"
                    value={categoryData.title}
                    handleChange={handleChange}
                    error={errors.title}
                    placeholder="Title"
                />

                <Field
                    id="description"
                    label="Description"
                    type="text"
                    name="description"
                    value={categoryData.description}
                    handleChange={handleChange}
                    error={errors.description}
                    placeholder="Description"
                />

                <ImageUpload
                    handleImageChange={handleImageChange}
                    error={errors.image}
                />

                <Field
                    id="max_price"
                    label="Max Price"
                    type="number"
                    name="max_price"
                    value={categoryData.max_price}
                    handleChange={handleChange}
                    error={errors.max_price}
                    placeholder="Max Price"
                />

                <Field
                    id="min_price"
                    label="Min Price"
                    type="number"
                    name="min_price"
                    value={categoryData.min_price}
                    handleChange={handleChange}
                    error={errors.min_price}
                    placeholder="Min Price"
                />

                <Field
                    id="priority"
                    label="Priority"
                    type="number"
                    name="priority"
                    value={categoryData.priority}
                    handleChange={handleChange}
                    error={errors.priority}
                    placeholder="Priority"
                />

                <ToggleBtn
                    isShow={categoryData.isShow}
                    toggleIsShow={() => setCategoryData(prev => ({ ...prev, isShow: !prev.isShow }))}
                />

                <div className="wrap">
                    <button onClick={handleSubmit}>
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NewCategory;
