import React, { useState } from 'react';

const ImageUpload = ({ handleImageChange, error }) => {
    const [selectedImage, setSelectedImage] = useState(null);

    const onImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setSelectedImage(imageUrl);
            handleImageChange(e); // Call the original handler if needed
        }
    };

    const removeImage = () => {
        setSelectedImage(null);
        handleImageChange({ target: { files: [] } }); // Clear the file input
    };

    return (
        <div id="image-upload">
            <label htmlFor="image">Image</label>
            <label className="custom-file-upload" htmlFor="image">
                Choose File
            </label>
            <input
                type="file"
                id="image"
                name="image"
                onChange={onImageChange}
                accept="image/*" // Accept only images
            />
            {selectedImage && (
                <div className="image-preview">
                    <img src={selectedImage} alt="Selected Preview" />
                    <button className="remove-image" onClick={removeImage}>
                        &times; {/* Close icon */}
                    </button>
                </div>
            )}
            {error && <span className="error">{error}</span>}
        </div>
    );
};

export default ImageUpload;
