import React from "react";
import { getCurrentDate } from "../../utils/Functions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from "react-redux";
import { settingStorageData } from './../../app/Slices/StorageSlice';

const Header = () => {
  const currentDate = getCurrentDate();
  const dispatch = useDispatch();
  const isNavOpen = useSelector((state) => state.storage?.isNavbarOpen);

  return (
    <div className="header">
      <div className="title">
        <div className="logo_img d-flex d-md-none">
          <img src="/images/logo/blackIcon.png" alt="Logo" />
        </div>
        {!isNavOpen && <div className="logo_img " onClick={() => dispatch(settingStorageData({ field: 'isNavbarOpen', value: true }))}>
          <img src="/images/logo/blackIcon.png" alt="Logo" />
        </div>}

        <div className="text">Gorella Mechanix</div></div>
      <span className="d-none d-md-block">{currentDate}</span>
      <div className="bar d-block d-md-none" onClick={() => dispatch(settingStorageData({ field: 'isDrawerOpen', value: true }))}>
        <FontAwesomeIcon size='lg' className="icon" icon={faBars} />
      </div>
    </div>
  );
};

export default Header;
