import { useState } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import UserSideApi from "../../../api/UserSideApi";

const ChangePassword = () => {
    const { ChangeUserPassword } = UserSideApi();
    const currentUser = useSelector((state) => state.userStorageData?.UserData);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errors, setErrors] = useState({});

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validatePasswords();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        const payload = {
            username: currentUser.username,
            oldPassword,
            newPassword,
        };

        ChangeUserPassword(payload);
        // console.log(JSON.stringify(payload, null, 2));
    };

    const validatePasswords = () => {
        const errors = {};
        if (!oldPassword) errors.oldPassword = "Old password is required";
        if (!newPassword) errors.newPassword = "New password is required";
        if (newPassword !== confirmPassword) {
            errors.confirmPassword = "Passwords do not match";
        }
        return errors;
    };

    return (
        <div className="ChangeProfile">
            <div className="icon">
                <FontAwesomeIcon size="lg" icon={faLock} />
            </div>
            <div className="title">Change Password</div>
            <form className="detailsForm" onSubmit={handleSubmit}>
                <div className="rows">
                    <div className="title">Username</div>
                    <div className="value">
                        <input type="text" value={currentUser?.username} disabled />
                    </div>
                </div>
                <div className="rows">
                    <div className="title">Old Password</div>
                    <div className="value">
                        <input
                            type="password"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                        />
                        {errors.oldPassword && <span className="error">{errors.oldPassword}</span>}
                    </div>
                </div>
                <div className="rows">
                    <div className="title">New Password</div>
                    <div className="value">
                        <input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                        {errors.newPassword && <span className="error">{errors.newPassword}</span>}
                    </div>
                </div>
                <div className="rows">
                    <div className="title">Confirm Password</div>
                    <div className="value">
                        <input
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        {errors.confirmPassword && <span className="error">{errors.confirmPassword}</span>}
                    </div>
                </div>
                <button type="submit" className="submitButton">Change Password</button>
            </form>
        </div>
    );
};

export default ChangePassword;
