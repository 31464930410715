import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getfullMenuData } from "../../../app/Slices/FullMenuSlice";
import "./../../../Style/pages/FullMenu.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";

const FullMenu = () => {
    const dispatch = useDispatch();
    const { fullMenuData, isLoading } = useSelector(state => state.fullMenuData);

    useEffect(() => {
        if (!fullMenuData) {
            dispatch(getfullMenuData());
        }
    }, [dispatch, fullMenuData]);

    return (
        <div className="MenuPage">
            <div className="menu-header">
                <h1>Our Full Menu</h1>
                <p>Explore a variety of categories with a selection of our finest items.</p>
                <button className="whereToGo" onClick={() => window.history.back()}><FontAwesomeIcon size="3x" icon={faArrowAltCircleLeft} /></button>
            </div>
            <div className="menu-body">
                <div className="full-menu-container">
                    {isLoading ? (
                        <p>Loading...</p>
                    ) : (
                        fullMenuData && fullMenuData.categories.map(category => (
                            <div key={category.id} className="menu-category-card">
                                <div className="menu-category-header">{category.title}</div>
                                <img
                                    src={`/images/menu/${category.image_url}`}
                                    alt={category.title}
                                    className="menu-category-image"
                                />
                                <div className="menu-category-content">
                                    <p className="description">{category.description}</p>
                                    <p className="price-range">
                                        Price Range: <span>${category.min_price}</span> - <span>${category.max_price}</span>
                                    </p>
                                </div>
                                <ul className="menu-items">
                                    {category.items.map(item => (
                                        <li key={item.id}>
                                            <div className="itemBody">
                                                <span className="item-title">{item.title}</span>
                                                <span className="item-description">{item.description}</span>
                                            </div>
                                            <span className="item-price">${item.price}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))
                    )}
                </div>
            </div>
            <div className="menu-footer">
                <p>Thank you for visiting our menu. We look forward to serving you!</p>
                <button className="contact-button">Contact Us</button>
            </div>
        </div>
    );
}

export default FullMenu;


