import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { config } from "../../utils/Constants";
import AxiosRedux from "../../utils/AxiosRedux";
const URL = config.url;

// Initial state
const initialState = {
    isLoading: false,
    todos: null,  // Initialized to null
    filterBy: 'day',
};

// Async thunk to fetch todo data
export const getTodoData = createAsyncThunk(
    'TodoData/getTodoData',
    async ({ limit, page,filter }, { dispatch }) => {  // Removed unused `id`
        try {
            const response = await AxiosRedux(`${URL}todos?limit=${limit}&page=${page}&filter=${filter}`, 'GET');

            if (response && response.status === 200) {
                const todosData = response.data;
                dispatch(settodosDataAction({ data: todosData }));
                return todosData;
            } else {
                toast.error('Something went wrong while getting todo data');
                return false;
            }
        } catch (error) {
            toast.error('Something went wrong while getting todo data');
            return false;
        }
    }
);

// Slice for managing todo data
const TodoDataSlice = createSlice({
    name: "TodoData",
    initialState,
    reducers: {
        settingTodoData: (state, action) => {
            const { field, value } = action.payload;
            state[field] = value;
          },
        settodosDataAction: (state, action) => {
            const { data } = action.payload;
            state.todos = data;  // Assign the fetched data to `todos`
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTodoData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getTodoData.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(getTodoData.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

// Export actions and reducer
export const { settodosDataAction, settingTodoData } = TodoDataSlice.actions;
export default TodoDataSlice.reducer;