import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./../../Style/components/ModalStyle.scss";
import { useState, useEffect } from 'react';
import Field from '../Render/Field';
import StaticDataAPI from '../../api/StaticDataApi';
import StaticDataItem from './../../utils/StaticData.json';

function NewStaticDataModal(props) {
    const { CreateStatic } = StaticDataAPI();

    const [clientData, setClientData] = useState({
        name: "",
        type: props?.data?.type,
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (props?.data) {
            setClientData({
                name: props?.data?.name,
                type: props?.data?.type,
            });
        }
    }, [props?.data]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setClientData({
            ...clientData,
            [name]: value
        });
    };

    const validate = () => {
        let validationErrors = {};
        if (!clientData.name) validationErrors.name = "Name is required";

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate()) {
            CreateStatic(clientData).then((response) => {
                if (response === 201) {
                    setClientData({
                        name: "",
                        type: props?.data?.type,
                    })
                    props.onHide();
                }
            });

        } else {
            console.log("Validation failed");
        }
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <div className='HeaderTitle'>
                        New Static Data
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="formsection">

                    <Field
                        id="name"
                        label="Name"
                        type="text"
                        name="name"
                        value={clientData.name}
                        handleChange={handleChange}
                        error={errors.name}
                        placeholder="Enter name"
                    />

                    {/* <Field
                        id="type"
                        label="Type"
                        type="text"
                        name="type"
                        value={clientData.type}
                        handleChange={handleChange}
                        error={errors.type}
                        placeholder="Enter type"
                        isDisabled={props?.data?.type != undefined}
                    /> */}
                    <div className="field">
                        <label>Type</label>
                        <select
                            name="type"
                            value={clientData.type}
                            onChange={handleChange}
                            disabled={props?.data?.type != undefined}
                        >
                            {StaticDataItem.map((item) => (
                                <option key={item.key} value={item.key}>
                                    {item.title}
                                </option>
                            ))}
                        </select>
                    </div>


                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" className="wrap" onClick={handleSubmit}>Create</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default NewStaticDataModal;