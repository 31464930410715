import React, { useEffect, useState } from "react";
import "./../../Style/pages/Pages.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faEye, faEyeSlash, faFile, faPenToSquare, faPlus } from "@fortawesome/free-solid-svg-icons";
import { getmenuCategorySlice } from "../../app/Slices/MenuSlice";
import { useDispatch, useSelector } from "react-redux";
import TableSkeleton from "../../components/BodyContent/TableSkeleton";
import SimpleTable from "../../components/BodyContent/SimpleTable";
import { useNavigate } from "react-router-dom";
import CategoryMenuModal from "../../components/Modals/CategoryMenuModal";


function Menu() {
    const [getIsHide, setIsHide] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const menuData = useSelector((state) => state.menuData);
    const [categoryData, setcategoryData] = useState([]);
    const [getModalShow, setModalShow] = useState(false);

    useEffect(() => {
        if (menuData.categoryData === null) {
            dispatch(getmenuCategorySlice())
        }
    }, [menuData.categoryData, dispatch])

    const handleCategoriySelected = (id) => {
        navigate(`/menu/Category/${id}`);
    }

    const columns = [
        // { Header: 'ID', accessor: 'id', Cell: ({ value }) => <div>#{value}</div> },
        { Header: '', accessor: 'image_url', Cell: ({ value }) => <img className="image" src={"images/menu/" + value} alt="" /> },
        { Header: 'Title', accessor: 'title' },
        { Header: 'Description', accessor: 'description' },
        { Header: 'Max Price', accessor: 'max_price' },
        { Header: 'Min Price', accessor: 'min_price' },
        { Header: 'Priority', accessor: 'priority' },
        { Header: 'Is Show', accessor: 'isShow', Cell: ({ value }) => <div>{value ? "Yes" : "No"}</div> },
        {
            Header: 'Action',
            accessor: 'action', // Use a unique accessor here
            Cell: ({ value, row }) => (
                <div className="actionBar">
                    <div className="EditBtn" onClick={() => { setModalShow(true); setcategoryData(row.original) }}><FontAwesomeIcon size='lg' icon={faPenToSquare} /></div>
                    <button className="GreenBtn" onClick={() => { handleCategoriySelected(row.original.id) }}><FontAwesomeIcon size='lg' icon={faArrowRight} /></button>
                </div>
            )
        },
    ]

    return (
        <>
            <div className="bodyPage">
                <div className="topSection">
                    <div className="text">Menu Category Page</div>
                    <div className="d-flex">
                        <div className="grayBtn" onClick={() => setIsHide(!getIsHide)}>
                            <FontAwesomeIcon size="lg" icon={getIsHide ? faEye : faEyeSlash} />
                        </div>
                        <div className="grayBtn" onClick={() => navigate('/full-menu')}><FontAwesomeIcon size='lg' icon={faFile} /></div>
                        <div className="addNew" onClick={() => navigate('/menu/newCategory')}><FontAwesomeIcon size='lg' icon={faPlus} /></div>
                    </div>

                </div>

                {menuData?.categoryData == null ?
                    <TableSkeleton />
                    :
                    <SimpleTable data={menuData?.categoryData?.categories} columns={columns} totalPages={1} currentPage={1} handleNextPage={() => { }} handlePrevPage={() => { }} />
                }

                <CategoryMenuModal
                    show={getModalShow}
                    onHide={() => setModalShow(false)}
                    data={categoryData}
                />
            </div>
        </>
    );
}
export default Menu;
