import { useTable, usePagination } from 'react-table';
import './../../Style/components/table.scss';
import React, { useState } from 'react';

const SimpleTable = ({
    data,
    columns,
    totalPages = 0,
    currentPage = 0,
    handleNextPage = () => { },
    handlePrevPage = () => { },
}) => {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        state: { },
    } = useTable(
        { columns, data },
        usePagination
    );
    return (
        <div className='table-container'>

            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.length > 0 ? (
                        page.map((row, rowIndex) => {
                            prepareRow(row);
                            return (
                                <React.Fragment key={row.id}>
                                    <tr id={rowIndex} {...row.getRowProps()}>
                                        {row.cells.map((cell) => (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        ))}
                                    </tr>
                                </React.Fragment>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={columns.length + 1}>
                                No data found
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

            {totalPages > 1 && <div className='pagination'>
                <div className='pages'>
                    Page <strong>{currentPage} of {totalPages}</strong>
                </div>

                <div className='whereToGo'>
                    <button onClick={() => { handlePrevPage(); }} disabled={currentPage === 1}>Previous</button>
                    <button onClick={() => { handleNextPage(); }} disabled={currentPage === totalPages}>Next</button>
                </div>
            </div>}
        </div>
    );
};

export default SimpleTable;
