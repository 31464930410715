import React from 'react';

const ToggleBtn = ({ isShow, toggleIsShow }) => {
    return (
        <div id="toggle">
            <label htmlFor="isShow">Is Show</label>
            <input
                type="checkbox"
                id="isShow"
                checked={isShow}
                onChange={toggleIsShow}
            />
            {/* <span>{isShow ? "Visible" : "Hidden"}</span> */}
        </div>
    );
};

export default ToggleBtn;
