import { useState } from 'react';
import "./../../../Style/pages/Form.scss";
import "./../../../Style/pages/Pages.scss";
import CarAPI from "../../../api/CarApi";
import { useDispatch, useSelector } from 'react-redux';
import { getclientCarsData } from '../../../app/Slices/SelectedClientDataSlice';
import Field from '../../../components/Render/Field';
import DropdownValue from '../../../components/Render/DropdownValue';
import ImageUpload from '../../../components/Render/ImageUpload';

const NewCar = ({ clientId, setDisplayedComponent }) => {
    const staticData = useSelector(state => state.storage.staticData);
    const { CreateCar } = CarAPI();
    const dispatch = useDispatch();

    const [clientData, setClientData] = useState({
        plate: "",
        model: "",
        brand: "",
        year: "",
        color: "",
        engine: "",
    });

    const [image, setImage] = useState(null);
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setClientData({
            ...clientData,
            [name]: value
        });
    };

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    const validate = () => {
        let validationErrors = {};
        if (!clientData.plate) validationErrors.plate = "Plate is required";
        if (!clientData.model) validationErrors.model = "Model is required";
        if (!clientData.brand) validationErrors.brand = "Brand is required";
        if (!clientData.year) validationErrors.year = "Year is required";
        if (!clientData.color) validationErrors.color = "Color is required";
        if (!clientData.engine) validationErrors.engine = "Engine is required";
        if (!image) validationErrors.image = "Image is required";

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate()) {
            const formData = new FormData();
            formData.append("plate", clientData.plate);
            formData.append("model", clientData.model);
            formData.append("brand", clientData.brand);
            formData.append("year", clientData.year);
            formData.append("color", clientData.color);
            formData.append("engine", clientData.engine);
            formData.append("image", image);
            formData.append("client_id", clientId);

            CreateCar(formData).then((response) => {
                if (response === 201) {
                    dispatch(getclientCarsData({ id: clientId }));
                    setDisplayedComponent("ClientCar");
                }
            });
        } else {
            console.log("Validation failed");
        }
    };

    return (
        <div className="formsection">
            <h1 className={Object.keys(errors).length > 0 ? "danger" : ""}>
                New Car Form
            </h1>

            <Field
                label="Plate"
                type="text"
                name="plate"
                placeholder="Plate"
                value={clientData.plate}
                handleChange={handleChange}
                error={errors.plate}
            />

            <DropdownValue
                id="brand"
                label="Brand"
                name="brand"
                value={clientData.brand}
                handleChange={handleChange}
                error={errors.brand}
                placeholder="Select a Brand"
                options={staticData.carbrand}
                type="carbrand"
                isOther={false}
            />

            <DropdownValue
                id="model"
                label="Model"
                name="model"
                value={clientData.model}
                handleChange={handleChange}
                error={errors.model}
                placeholder="Select a Model"
                options={staticData.carmodel}
                type="carmodel"
                isOther={false}
            />

            <DropdownValue
                id="year"
                label="Year"
                name="year"
                value={clientData.year}
                handleChange={handleChange}
                error={errors.year}
                placeholder="Select a Year"
                options={staticData.caryear}
                type="caryear"
                isOther={false}
            />

            <DropdownValue
                id="color"
                label="Color"
                name="color"
                value={clientData.color}
                handleChange={handleChange}
                error={errors.color}
                placeholder="Select a Color"
                options={staticData.carcolor}
                type="carcolor"
                isOther={false}
            />

            <DropdownValue
                id="engine"
                label="Engine"
                name="engine"
                value={clientData.engine}
                handleChange={handleChange}
                error={errors.engine}
                placeholder="Select an Engine"
                options={staticData.carengine}
                type="carengine"
                isOther={false}
            />

            <ImageUpload
                handleImageChange={handleImageChange}
                error={errors.image}
            />

            <div className="wrap">
                <button onClick={handleSubmit}>
                    Submit
                </button>
            </div>
        </div>
    );
};

export default NewCar;
