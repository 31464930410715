import { toast } from "react-toastify";
import useInterceptorAxios from "../utils/axios";
import { useNavigate, useParams } from "react-router-dom";

const TaskApi = () => {
    const Client = useInterceptorAxios();
    const navigate = useNavigate();
    const { RepairId } = useParams();

    const CreateTask = async (newRepairData) => {
        try {
            const response = await Client.post(`/task`, {
                issue: newRepairData?.issue,
                items: newRepairData?.items,
                price: newRepairData?.price,
                note: newRepairData?.note,
                repair_id: newRepairData?.repair_id,
            });
            if (response?.status === 201) {
                toast.success(response?.data?.message);
            }

            return response?.status;
        } catch (e) {
            toast.error("Something went wrong while Creating New Car Repair Task!!");
        }
    };

    const GetTaskById = async (id) => {
        try {
            const response = await Client.get(`/task/${id}`);
            return response;
        } catch (e) {
            toast.error("Something went wrong while Getting Task info !!");
        }
    };

    const UpdateTask = async (id, newRepairData) => {
        try {
            const response = await Client.put(`/task/${id}`, {
                issue: newRepairData?.issue,
                items: newRepairData?.items,
                price: newRepairData?.price,
                note: newRepairData?.note,
                repair_id: RepairId,
            });
            if (response?.status === 200) {
                toast.success(response?.data?.message);
                navigate(0);
            }

            return response?.status;
        } catch (e) {
            toast.error("Something went wrong while Updating Car Repair Task!!");
        }
    };


    return {
        CreateTask,
        GetTaskById,
        UpdateTask
    };
};

export default TaskApi;
