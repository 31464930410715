import { toast } from "react-toastify";
import useInterceptorAxios from "../utils/axios";

const TodoApi = () => {
    const Client = useInterceptorAxios();

    const CreateTodo = async (todoData) => {
        try {
            const response = await Client.post(`/todo`, {
                title: todoData?.title,
                description: todoData?.description,
                completed: todoData?.completed,
            });
            if (response?.status === 201) {
                toast.success(response?.data?.message);
            }

            return response?.status;
        } catch (e) {
            toast.error("Something went wrong while Creating New Todo!!");
        }
    };

    const GetTodoById = async (id) => {
        try {
            const response = await Client.get(`/todo/${id}`);
            return response;
        } catch (e) {
            toast.error("Something went wrong while Getting Todo info !!");
        }
    };

    const UpdateTodo = async (id, todoData, isCompleted) => {
        try {
            const response = await Client.put(`/todo/${id}`, {
                title: todoData?.title,
                description: todoData?.description,
                completed: isCompleted,
            });
            if (response?.status === 200) {
                toast.success(response?.data?.message);
            }

            return response?.status;
        } catch (e) {
            toast.error("Something went wrong while Updating Todo!!");
        }
    };


    return {
        CreateTodo,
        GetTodoById,
        UpdateTodo
    };
};

export default TodoApi;
