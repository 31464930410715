import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { config } from "../../utils/Constants";
import AxiosRedux from "../../utils/AxiosRedux";
const URL = config.url;

const initialState = {
    isLoading: false,
    fullMenuData: null,
    topCategory: null,
    topCatItem: null,
};

// Thunk to fetch full menu data
export const getfullMenuData = createAsyncThunk(
    'fullMenuData/getfullMenuData',
    async (_, { rejectWithValue }) => {
        try {
            const response = await AxiosRedux(`${URL}/getFullMenu`, 'GET');
            if (response.status === 200) {
                return response.data;
            } else {
                toast.error('Error while fetching Menu Data');
                return rejectWithValue('Error fetching menu data');
            }
        } catch (error) {
            toast.error('Error while fetching Menu Data');
            return rejectWithValue(error.message);
        }
    }
);

// Thunk to fetch top category
export const getTopCategory = createAsyncThunk(
    'fullMenuData/getTopCategory',
    async (_, { rejectWithValue }) => {
        try {
            const response = await AxiosRedux(`${URL}/menu-categories`, 'GET');
            if (response.status === 200) {
                return response.data;
            } else {
                toast.error('Error while fetching Category Data');
                return rejectWithValue('Error fetching category data');
            }
        } catch (error) {
            toast.error('Error while fetching Category Data');
            return rejectWithValue(error.message);
        }
    }
);

// Thunk to fetch top category item by ID
export const getTopCatItem = createAsyncThunk(
    'fullMenuData/getTopCatItem',
    async (id, { rejectWithValue }) => {
        try {
            const response = await AxiosRedux(`${URL}/menu-items/category/${id}`, 'GET');
            if (response.status === 200) {
                return response.data;
            } else {
                toast.error('Error while fetching Item Data');
                return rejectWithValue('Error fetching item data');
            }
        } catch (error) {
            toast.error('Error while fetching Item Data');
            return rejectWithValue(error.message);
        }
    }
);

// Full Menu Slice
const FullMenuSlice = createSlice({
    name: "fullMenuData",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Handle getfullMenuData
            .addCase(getfullMenuData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getfullMenuData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.fullMenuData = action.payload;
            })
            .addCase(getfullMenuData.rejected, (state) => {
                state.isLoading = false;
            })
            // Handle getTopCategory
            .addCase(getTopCategory.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getTopCategory.fulfilled, (state, action) => {
                state.isLoading = false;
                state.topCategory = action.payload;
            })
            .addCase(getTopCategory.rejected, (state) => {
                state.isLoading = false;
            })
            // Handle getTopCatItem
            .addCase(getTopCatItem.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getTopCatItem.fulfilled, (state, action) => {
                state.isLoading = false;
                state.topCatItem = action.payload;
            })
            .addCase(getTopCatItem.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

export default FullMenuSlice.reducer;
