import { faMap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LocateUs = () => {
    // Replace with your workshop address or coordinates
    // const address = "1600+Amphitheatre+Parkway,+Mountain+View,+CA"; // Example address
    // const mapSrc = `https://www.google.com/maps?q=${address}&hl=en&z=15&output=embed`;

    return (
        <div className="CardSection">
            <div className="cardHeader">
                <div className="iconTitle">
                    <div className="icons"><FontAwesomeIcon size="lg" icon={faMap} /></div>
                    <div className="title">Locate Us</div>
                </div>
            </div>
            <div className="cardBody">
                {/* <iframe
                    src={mapSrc}
                    width="100%"
                    height="300"
                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen
                    aria-hidden="false"
                    tabIndex="0"
                    title="Map"
                ></iframe> */}
                Locate Us
            </div>
            <div className="cardFooter">
                {/* Footer content if needed */}
            </div>
        </div>
    );
};

export default LocateUs;
