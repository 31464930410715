import React from 'react'
import './../Style/pages/NotFound.scss'

const NotAuthorized = () => {
    return (
        <div className="flex-container">
            <div className="text-center">
                <h1>
                    <span className="fade-in" id="digit1">4</span>
                    <span className="fade-in" id="digit2">0</span>
                    <span className="fade-in" id="digit3">1</span>
                </h1>
                <h3 className="fadeIn">NOT AUTHORIZED</h3>
                <button onClick={() => window.location.href = "/"}>Return To Login</button>
            </div>
        </div>
    )
}

export default NotAuthorized