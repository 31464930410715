import { toast } from "react-toastify";
import useInterceptorUserAxios from "../utils/UserAxios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { settingUserStorageData } from "../app/Slices/UserStoarageSlices";

const UserSideApi = () => {
    const Client = useInterceptorUserAxios();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const CurrentUser = useSelector((state) => state.userStorageData);

    const GetProfile = async () => {
        try {
            const response = await Client.get(`/getProfile?username=${sessionStorage.getItem("username")}`);
            // console.log(response);

            if (response?.status === 200) {
                dispatch(settingUserStorageData({ field: "UserData", value: response.data }));
            }

            return response;
        } catch (e) {
            toast.error("Something went wrong while Getting Profile !!");
        }
    }

    const ChangeUserPassword = async (data) => {
        try {
            const response = await Client.post(`/change-password`, data);
            console.log(response);

            if (response?.status === 200) {
                toast.success(response?.data?.message);
            }

            return response;
        } catch (e) {
            toast.error("Something went wrong while Changing Password !!");
        }
    }




    return {
        GetProfile,
        ChangeUserPassword
    };
};

export default UserSideApi;
