import { useState } from 'react';
import "./../../../Style/pages/Form.scss";
import "./../../../Style/pages/Pages.scss";
import { useDispatch } from 'react-redux';
import { getCaRepairsData } from '../../../app/Slices/SelectedCarDataSlice';
import TaskApi from '../../../api/TaskApi';
import { getrepairTaskData } from '../../../app/Slices/SelectedRepairDataSlice';
import Field from '../../../components/Render/Field';

const NewTask = ({ repairId, setDisplayedComponent }) => {
    const { CreateTask } = TaskApi();
    const dispatch = useDispatch();
    const [RepairData, setRepairData] = useState({
        issue: "",
        items: "",
        price: "",
        note: ""
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRepairData({
            ...RepairData,
            [name]: value
        });
    };

    const validate = () => {
        let validationErrors = {};
        if (!RepairData.issue) validationErrors.issue = "Hand Cost is required";
        if (!RepairData.items) validationErrors.items = "items is required";
        if (!RepairData.price) validationErrors.price = "price is required";
        if (!RepairData.note) validationErrors.note = "Note is required";

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate()) {
            CreateTask({
                issue: RepairData.issue,
                items: RepairData.items,
                price: RepairData.price,
                note: RepairData.note,
                repair_id: repairId
            }).then((response) => {
                if (response === 201) {
                    dispatch(getrepairTaskData({ id: repairId }));
                    setDisplayedComponent("RepairTask");
                }
            });

        } else {
            console.log("Validation failed");
        }
    };

    return (
        <div className="formsection">
            <h1 className={Object.keys(errors).length > 0 ? "danger" : ""}>New Task Form</h1>

            {/* Using Field component for each form input */}
            <Field
                id="issue"
                label="Issue"
                type="text"
                name="issue"
                value={RepairData.issue}
                handleChange={handleChange}
                error={errors.issue}
                placeholder="Enter the issue"
            />

            <Field
                id="items"
                label="Items"
                type="text"
                name="items"
                value={RepairData.items}
                handleChange={handleChange}
                error={errors.items}
                placeholder="Enter the items"
            />

            <Field
                id="price"
                label="Price"
                type="text"
                name="price"
                value={RepairData.price}
                handleChange={handleChange}
                error={errors.price}
                placeholder="Enter the price"
            />

            <Field
                id="note"
                label="Note"
                type="text"
                name="note"
                value={RepairData.note}
                handleChange={handleChange}
                error={errors.note}
                placeholder="Enter a note"
            />

            <div className="wrap">
                <button onClick={handleSubmit}>Submit</button>
            </div>
        </div>
    );
};

export default NewTask;