import { toast } from "react-toastify";
import useInterceptorAxios from "../utils/axios";
import { useNavigate } from "react-router-dom";

const ClientAPI = () => {
    const Client = useInterceptorAxios();
    const navigate = useNavigate();

    // const CreateClient = async (newClientData) => {
    //     console.log(newClientData);
    //     try {
    //         const response = await Client.post(`/client`, {
    //             fName: newClientData?.fName,
    //             lName: newClientData?.lName,
    //             email: newClientData?.email,
    //             phoneNb: newClientData?.phoneNb,
    //             username: newClientData?.username,
    //             address: newClientData?.address,
    //         });
    //         if (response?.status === 201) {
    //             toast.success(response?.data?.message);
    //             navigate("/client");
    //         }

    //         return response?.status;
    //     } catch (e) {
    //         toast.error("Something went wrong while Creating Client !!");
    //     }
    // };
    const CreateClient = async (formData) => {
        try {
            const response = await Client.post(`/client`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response?.status === 201) {
                toast.success(response?.data?.message);
                navigate("/client");
            }

            return response?.status;
        } catch (e) {
            toast.error("Something went wrong while Creating Client!!");
        }
    };



    const GetClientById = async (id) => {
        try {
            const response = await Client.get(`/client/${id}`);
            return response;
        } catch (e) {
            toast.error("Something went wrong while Getting Client info !!");
        }
    };

    // const UpdateClient = async (id, clientData) => {
    //     try {
    //         const response = await Client.put(`/client/${id}`, {
    //             fName: clientData?.fName,
    //             lName: clientData?.lName,
    //             email: clientData?.email,
    //             phoneNb: clientData?.phoneNb,
    //             address: clientData?.address,
    //             username: clientData?.username
    //         });
    //         if (response?.status === 200) {
    //             toast.success(response?.data?.message);
    //             navigate(0);
    //         }

    //         return response?.status;
    //     } catch (e) {
    //         toast.error("Something went wrong while Updating Client !!");
    //     }
    // };
    const UpdateClient = async (id, clientData) => {
        try {
            const response = await Client.put(`/client/${id}`, clientData, {
                headers: {
                    'Content-Type': 'application/json' // Explicitly setting the content type to handle JSON data
                }
            });

            if (response?.status === 200) {
                toast.success(response?.data?.message);
                navigate(0); // Reload the page or update state as needed
            }

            return response?.status;
        } catch (e) {
            toast.error("Something went wrong while updating the client!");
            console.error(e); // Log the error to the console
        }
    };


    return {
        CreateClient,
        GetClientById,
        UpdateClient
    };
};

export default ClientAPI;
