import { faInbox } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ContactForm from "./Component/ContactForm"

const ContactUsForm = () => {

    return (
        <div className="CardSection">
            <div className="cardHeader">
                <div className="iconTitle">
                    <div className="icons"><FontAwesomeIcon size="lg" icon={faInbox} /></div>
                    <div className="title">Contact Us</div>
                </div>

                <div className="actionBar">
                    <button className="greenBtn">View</button>
                </div>
            </div>
            <div className="cardBody">
                <ContactForm />
            </div>

            <div className="cardFooter">

            </div>
        </div>
    )
}

export default ContactUsForm