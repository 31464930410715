import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './../../Style/components/InternalNavbar.scss';

const InternalNavbar = ({ screens,setActiveScreen,activeScreen }) => {

    const handleClick = (screen) => {
        setActiveScreen(screen.label);
    };

    return (
        <nav className="internal-navbar">
            <ul className="nav-list">
                {screens.map((screen, index) => (
                    <li
                        key={index}
                        className={`nav-item ${activeScreen === screen.label ? 'active' : ''}`}
                        onClick={() => handleClick(screen)}
                    >
                        <FontAwesomeIcon icon={screen.icon} className="nav-icon" />
                        <a href={screen.link}>{screen.label}</a>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default InternalNavbar;
