import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Layout, LoginPage, NotFound, ToDo, Client, NewClient, SelectedClient, SelectedClientCar, SelectedClientCarRepair, NotAuthorized, UserLayout, UserHome, UserDashboard, UserContact, Menu, NewCategory, CategoryMenu, FullMenu, ContolPanel } from "./pages";
import "./Style/common.scss";
import RouteGuard from "./utils/RouteGuard";
import { useEffect } from "react";
import { getStaticDataSlice } from "./app/Slices/StorageSlice";
import { useDispatch, useSelector } from "react-redux";

function App() {
  const dispatch = useDispatch();
  const staticData = useSelector(state => state.storage.staticData);
  useEffect(() => {
    if (!staticData.staticData)
      dispatch(getStaticDataSlice());
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginPage />} />

        <Route
          path="todo"
          element={
            <RouteGuard requiredRole="admin">
              <Layout />
            </RouteGuard>
          }>
          <Route index element={<ToDo />} />
        </Route>

        <Route
          path="menu"
          element={
            <RouteGuard requiredRole="admin">
              <Layout />
            </RouteGuard>
          }>
          <Route index element={<Menu />} />
          <Route path="newCategory" element={<NewCategory />} />
          <Route path="/menu/Category/:CategoryId" element={<CategoryMenu />} />

        </Route>

        <Route
          path="client"
          element={
            <RouteGuard requiredRole="admin">
              <Layout />
            </RouteGuard>
          }>
          <Route index element={<Client />} />
          <Route path="new" element={<NewClient />} />
          <Route path=":ClientId" element={<SelectedClient />} />
          <Route path=":ClientId/car/:CarId" element={<SelectedClientCar />} />
          <Route path=":ClientId/car/:CarId/repair/:RepairId" element={<SelectedClientCarRepair />} />
        </Route>

        <Route
          path="userProfile"
          element={
            <RouteGuard requiredRole="user">
              <UserLayout />
            </RouteGuard>
          }>
          <Route path="home" element={<UserHome />} />
          <Route path="dashboard" element={<UserDashboard />} />
          <Route path="contact" element={<UserContact />} />
        </Route>

        <Route
          path="controlPanel"
          element={
            <RouteGuard requiredRole="admin">
              <Layout />
            </RouteGuard>
          }>
          <Route index element={<ContolPanel />} />
        </Route>

        <Route path="not-authorized" element={<NotAuthorized />} />
        <Route path="full-menu" element={<FullMenu />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
