import { faAddressCard, faUser } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import UserSideApi from "../../../api/UserSideApi"
import { useSelector } from "react-redux"
import "./../../../Style/SubComp/ProfileSection.scss"
import { HandleLocalDate } from "../../../utils/FormattingData"
import ChangePassword from "./ChangePassword"

const ProfileSection = () => {
    const { GetProfile } = UserSideApi();
    const CurrentUserr = useSelector((state) => state.userStorageData);
    const [getIsChangePassword, setIsChangePassword] = useState(false);

    useEffect(() => {
        GetProfile()
    }, [])

    return (
        <div className="CardSection">
            <div className="cardHeader">
                <div className="iconTitle">
                    <div className="icons"><FontAwesomeIcon size="lg" icon={faAddressCard} /></div>
                    <div className="title">Profile</div>
                </div>

                <div className="actionBar">
                    {/* <button className="greenBtn">View</button> */}
                    <button className="yellowBtn" onClick={() => setIsChangePassword(!getIsChangePassword)}>{getIsChangePassword ? 'Cancel' : 'Change Password'}</button>
                </div>
            </div>
            <div className="cardBody">

                {getIsChangePassword && (<ChangePassword />)}
                {CurrentUserr?.UserData && getIsChangePassword === false && (
                    <div className="Profile">
                        <div className="icon">
                            {CurrentUserr?.UserData?.image_url ? (
                                <img
                                    src={`/images/clients/${CurrentUserr.UserData.image_url}`}
                                    alt="User"
                                    className="user-icon"
                                    onError={(e) => e.target.src = '/images/defaultAvatar.png'} // Fallback image on error
                                />
                            ) : (
                                <FontAwesomeIcon size="lg" icon={faUser} />
                            )}
                        </div>

                        <div className="details">
                            <div className="rows">
                                <div className="title">Name</div>
                                <div className="value">{`${CurrentUserr?.UserData.fName} ${CurrentUserr?.UserData.lName}`}</div>
                            </div>
                            <div className="rows">
                                <div className="title">Email</div>
                                <div className="value">{CurrentUserr?.UserData.email}</div>
                            </div>
                            <div className="rows">
                                <div className="title">Phone</div>
                                <div className="value">{CurrentUserr?.UserData.phoneNb}</div>
                            </div>
                            <div className="rows">
                                <div className="title">Address</div>
                                <div className="value">{CurrentUserr?.UserData.address}</div>
                            </div>
                            <div className="rows Time">
                                <div className="title">Created At</div>
                                <div className="value">{HandleLocalDate(CurrentUserr?.UserData.createdAt)}</div>
                            </div>
                        </div>
                    </div>
                )}

            </div>

            <div className="cardFooter">
                {CurrentUserr?.UserData && <div>
                    <div className="username">{CurrentUserr?.UserData.username}</div>
                </div>
                }
            </div>
        </div>
    )

}

export default ProfileSection