import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { config } from "./../../utils/Constants";
import AxiosRedux from "./../../utils/AxiosRedux";
const URL = config.url;

const initialState = {
  activeTab: "",
  isDrawerOpen: false,
  isNavbarOpen: true,
  data: {
    name: "",
    email: "",
    password: "",
  },
  selectedUser: {
    name: "",
    email: "",
    password: "",
  },
  staticData: [],
  error: null,  // Added to manage error state
};

export const getStaticDataSlice = createAsyncThunk(
  'storage/getStaticDataSlice',
  async (_, { dispatch }) => {
      try {
          const response = await AxiosRedux(`${URL}/static`, 'GET');

          if (response && response.status === 200) {
              const categoryData = response.data;
              dispatch(settingStorageData({ field: 'staticData', value: categoryData })); // Fixed to set staticData
              return categoryData;
          } else {
              throw new Error('Failed to fetch data');  // Improved error handling
          }
      } catch (error) {
          toast.error('Something went wrong while getting menu categories.');
          return error.message;  // Return the error message
      }
  }
);

const StorageSlice = createSlice({
  name: "Storage",
  initialState,
  reducers: {
    settingStorageData: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    settingNestedStorage: (state, action) => {
      const { parentField, field, value } = action.payload;
      state[parentField][field] = value;
    },
    resetStorageData: (state) => {
      state.data = {
        name: "",
        email: "",
        password: "",
      };
      state.selectedUser = {
        name: "",
        email: "",
        password: "",
      };
      state.error = null;  // Reset error state
    },
    toggleDrawer: (state) => {
      state.isDrawerOpen = !state.isDrawerOpen;  // Toggle drawer open state
    },
    toggleNavbar: (state) => {
      state.isNavbarOpen = !state.isNavbarOpen;  // Toggle navbar open state
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStaticDataSlice.rejected, (state, action) => {
        state.error = action.payload;  // Set error on rejection
      });
  },
});

export const { settingStorageData, settingNestedStorage, resetStorageData, toggleDrawer, toggleNavbar } = StorageSlice.actions;

export default StorageSlice.reducer;
