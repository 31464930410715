import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./../../Style/components/ModalStyle.scss";
import { useState, useEffect } from 'react';
import MenuApi from '../../api/MenuApi';
import { useParams } from 'react-router-dom';
import Field from '../Render/Field';
import ToggleBtn from '../Render/ToggleBtn';

function ItemMenuModal(props) {
    const { UpdateItem } = MenuApi();
    const { CategoryId } = useParams();

    const [clientData, setClientData] = useState({
        title: props?.data?.title,
        description: props?.data?.description,
        image_url: props?.data?.image_url,
        price: props?.data?.price,
        priority: props?.data?.priority,
        isShow: props?.data?.isShow,
        category_id: CategoryId
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (props?.data) {
            setClientData({
                title: props?.data?.title,
                description: props?.data?.description,
                image_url: props?.data?.image_url,
                price: props?.data?.price,
                min_price: props?.data?.min_price,
                priority: props?.data?.priority,
                isShow: props?.data?.isShow,
                category_id: CategoryId
            });
        }
    }, [props?.data]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setClientData({
            ...clientData,
            [name]: value
        });
    };

    const validate = () => {
        let validationErrors = {};
        if (!clientData.title) validationErrors.title = "Title is required";
        if (!clientData.description) validationErrors.description = "Description is required";
        if (!clientData.price) validationErrors.price = "Price is required";
        if (!clientData.priority) validationErrors.priority = "Priority is required";
        // if (!clientData.isShow) validationErrors.isShow = "IsShow is required";

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate()) {
            UpdateItem(props?.data?.id, clientData).then((response) => {
                if (response === 200) {
                    props.onHide();
                }
            });

        } else {
            console.log("Validation failed");
        }
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <div className='HeaderTitle'>
                        Edit Item Category Menu
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="formsection">
                    <Field
                        id="title"
                        label="Title"
                        type="text"
                        name="title"
                        value={clientData.title}
                        handleChange={handleChange}
                        error={errors.title}
                    />

                    <Field
                        id="description"
                        label="Description"
                        type="text"
                        name="description"
                        value={clientData.description}
                        handleChange={handleChange}
                        error={errors.description}
                    />

                    <Field
                        id="price"
                        label="Price"
                        type="text"
                        name="price"
                        value={clientData.price}
                        handleChange={handleChange}
                        error={errors.price}
                    />

                    <Field
                        id="priority"
                        label="Priority"
                        type="text"
                        name="priority"
                        value={clientData.priority}
                        handleChange={handleChange}
                        error={errors.priority}
                    />

                    <ToggleBtn
                        isShow={clientData.isShow}
                        toggleIsShow={() => setClientData(prev => ({ ...prev, isShow: !prev.isShow }))}
                    />

                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" className="wrap" onClick={handleSubmit}>Edit</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ItemMenuModal;
