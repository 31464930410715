import { useSelector } from "react-redux";
import { useState } from "react";
import './../../../Style/pages/StaticDataScreen.scss';
import { faCar, faDroplet, faLayerGroup, faPenToSquare, faPlus, faUser, faGear, faCalendarDay, faTicket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NewStaticDataModal from "../../../components/Modals/NewStaticDataModal";
import UpdateStaticDataModal from "../../../components/Modals/UpdateStaticDataModal";
import StaticDataItem from "./../../../utils/StaticData.json";

const iconMap = {
    faCar: faCar,
    faDroplet: faDroplet,
    faUser: faUser,
    faLayerGroup: faLayerGroup,
    faGear: faGear,
    faCalendarDay: faCalendarDay,
    faTicket: faTicket
};

const StaticDataScreen = () => {
    const staticData = useSelector(state => state.storage.staticData);

    const [globalSearch, setGlobalSearch] = useState('');
    const [searchTerms, setSearchTerms] = useState({});
    const [getNewModalShow, setNewModalShow] = useState(false);
    const [getUpdateModalShow, setUpdateModalShow] = useState(false);
    const [getUpdateData, setUpdateData] = useState({});

    // const dataSections = StaticDataItem;
    const dataSections = StaticDataItem.map(section => ({
        ...section,
        icon: iconMap[section.icon] // Map icon string to the actual icon object
    }));

    const handleGlobalSearchChange = (e) => {
        setGlobalSearch(e.target.value);
    };

    const handleSectionSearchChange = (key, e) => {
        setSearchTerms({
            ...searchTerms,
            [key]: e.target.value,
        });
    };

    const filterData = (data) => {
        return data.filter(item =>
            item.name.toLowerCase().includes(globalSearch.toLowerCase())
        );
    };

    return (
        <div className="static-data-screen container">
            <h1 className="title">Static Data Overview</h1>

            <div className="search-container">
                <input
                    type="text"
                    placeholder="Search all..."
                    value={globalSearch}
                    onChange={handleGlobalSearchChange}
                    className="global-search form-control mb-3"
                />
                <div className="addNew" onClick={() => setNewModalShow(true)}><FontAwesomeIcon size='lg' icon={faPlus} /></div>
            </div>
            <div className="row">
                {dataSections.map(section => (
                    <div className="col-lg-6 col-md-12" key={section.key}>
                        <div className="data-section">
                            <div className="section-title">
                                <div className="">
                                    <FontAwesomeIcon icon={section.icon} className="me-2" />
                                    <span>{section.title}</span>
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        placeholder={`Search ${section.title.toLowerCase()}...`}
                                        value={searchTerms[section.key] || ''}
                                        onChange={(e) => handleSectionSearchChange(section.key, e)}
                                        className="section-search form-control"
                                    />
                                </div>
                            </div>

                            <ul className="data-list list-unstyled">
                                {staticData[section.key] &&
                                    filterData(staticData[section.key])
                                        .filter(item =>
                                            item.name.toLowerCase().includes(searchTerms[section.key]?.toLowerCase() || '')
                                        )
                                        .map(item => (
                                            <li key={item.uuid} className="data-item">
                                                <div className="data-card">
                                                    <div className="data-name">
                                                        <span>{item.name}</span>
                                                        <div className="EditBtn" onClick={() => { setUpdateData(item); setUpdateModalShow(true) }}><FontAwesomeIcon size='sm' icon={faPenToSquare} /></div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))
                                }
                            </ul>
                        </div>
                    </div>
                ))}
            </div>

            <NewStaticDataModal
                show={getNewModalShow}
                onHide={() => setNewModalShow(false)}

            />

            <UpdateStaticDataModal
                show={getUpdateModalShow}
                onHide={() => setUpdateModalShow(false)}
                data={getUpdateData}
            />
        </div>
    );
};

export default StaticDataScreen;