import React from "react";

const Field = ({ id, label, type, name, value = "", handleChange, error, placeholder, isDisabled = false }) => {
    return (
        <div className="field">
            <label htmlFor={id}>{label}</label>
            <input
                type={type}
                id={id}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                disabled={isDisabled}
            />
            {error && <span className="error">{error}</span>}
        </div>
    );
};

export default Field;
