import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import "./../Style/components/Outlet.scss";
import Header from "./BodyContent/Header";
import Navbar from "./BodyContent/Navbar";
import Drawer from 'react-modern-drawer'
import { useDispatch, useSelector } from 'react-redux';
import { settingStorageData } from './../app/Slices/StorageSlice';


function BodyContent() {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.storage?.isDrawerOpen);
  const isNavOpen = useSelector((state) => state.storage?.isNavbarOpen);

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="Outlet_Container">
      {isNavOpen && <div className="LeftBar d-none d-md-block">
        <Navbar />
      </div>}


      <div className="RightBar" style={{ width: isNavOpen && isWideScreen ? 'calc(100% - 250px)' : '100%' }}>
        <Header />

        <div className="body">
          <Outlet />
        </div>

        <div className="footer">
          2024 Copyrights © <span>Gorella</span>
        </div>
      </div>


      <Drawer
        open={isOpen}
        onClose={() => dispatch(settingStorageData({ field: 'isDrawerOpen', value: false }))}
        direction='left'
        className='drawer'
      >
        <div className="LeftBar">
          <Navbar />
        </div>
      </Drawer>
    </div>
  );
}

export default BodyContent;
