
import { useDispatch, useSelector } from "react-redux";
import "./../../../Style/pages/Todo.scss";
import TodoApi from "../../../api/TodoApi";
import TodoModal from "../../../components/Modals/TodoModal";
import { useState } from "react";
import { faCheck, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getTodoData } from "../../../app/Slices/TodoDataSlice";


const TodoBody = () => {
    const { UpdateTodo } = TodoApi();
    const todos = useSelector((state) => state.todoData?.todos);
    const [getTodoModalShow, setTodoModalShow] = useState(false);
    const [getTodoModalData, setTodoModalData] = useState({});
    const dispatch = useDispatch();

    const handleComplete = (todo) => {
        UpdateTodo(todo.id, todo, true).then((response) => {
            if (response === 200) {
                dispatch(getTodoData({ limit: 6, page: todos.currentPage, filter: todos.filterBy }));
            }
        })
    }

    return (
        <div className="TodoBody">
            <div className="newTodo">
                <div className="Title">New</div>

                <div className="todoCardDrager">

                    {todos?.newTodos?.map((todo) => (
                        <div className="todoCard" key={todo.id}>
                            <div className="actionBar">
                                <button className="completeBtn" onClick={() => { handleComplete(todo) }}><FontAwesomeIcon size="sm" icon={faCheck} /></button>
                                <button className="editBtn" onClick={() => {
                                    setTodoModalData(todo); setTodoModalShow(true)
                                }}><FontAwesomeIcon size="sm" icon={faPenToSquare} /></button>
                            </div>
                            <div className="title">{todo?.title}</div>
                            <div className="desc">{todo?.description}</div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="TodoCompleted">
                <div className="Title">Completed</div>

                <div className="todoCardDrager">
                    {todos?.completedTodos?.map((todo) => (
                        <div className="todoCard" key={todo.id}>
                            <div className="title">{todo?.title}</div>
                            <div className="desc">{todo?.description}</div>
                        </div>
                    ))}
                </div>
            </div>


            <TodoModal
                show={getTodoModalShow}
                onHide={() => setTodoModalShow(false)}
                data={getTodoModalData}
            />
        </div>
    )
}

export default TodoBody