import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faPenToSquare, faPlus } from '@fortawesome/free-solid-svg-icons';
import "./../../Style/pages/Pages.scss";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getclientData } from './../../app/Slices/ClientSlice';
import SimpleTable from '../../components/BodyContent/SimpleTable';
import TableSkeleton from '../../components/BodyContent/TableSkeleton';
import HandleLocalTime, { HandleLocalDate } from '../../utils/FormattingData';
import ClientModal from '../../components/Modals/ClientModal';

const Client = () => {
    const navigate = useNavigate();
    const clientData = useSelector(state => state.clientData);
    const dispatch = useDispatch();
    const [clientCarsData, setclientCarsData] = useState([]);
    const [getModalShow, setModalShow] = useState(false);
    useEffect(() => {
        if (clientData?.clientData?.clients == null) {
            dispatch(getclientData({ pageSize: 10, page: 1 }));
        }
    }, [])

    const handleClientSelected = (id) => {
        navigate(`/client/${id}`);
    }

    const columns = [
        { Header: '', accessor: 'image_url', Cell: ({ value }) => <img className="image" src={"/images/clients/" + value} alt="" /> },
        // { Header: 'ID', accessor: 'id', Cell: ({ value }) => <div>#{value}</div> },
        { Header: 'Name', accessor: 'fName' },
        { Header: 'Last Name', accessor: 'lName' },
        { Header: 'Email', accessor: 'email' },
        { Header: 'Phone', accessor: 'phoneNb' },
        { Header: 'Address', accessor: 'address' },
        { Header: 'created', accessor: 'createdAt', Cell: ({ value }) => HandleLocalDate(value) },
        { Header: 'updated', accessor: 'updatedAt', Cell: ({ value }) => HandleLocalDate(value) },
        {
            Header: 'Action',
            accessor: 'action', // Use a unique accessor here
            Cell: ({ value, row }) => (
                <div className="actionBar">
                    <div className="EditBtn" onClick={() => { setModalShow(true); setclientCarsData(row.original) }}><FontAwesomeIcon size='lg' icon={faPenToSquare} /></div>
                    <button className="GreenBtn" onClick={() => { handleClientSelected(row.original.id) }}><FontAwesomeIcon size='lg' icon={faArrowRight} /></button>
                </div>
            )
        },]

    const handleNextPage = () => {
        const clientDataOrNull = clientData?.clientData;

        if (clientDataOrNull != null) {
            const nextPage = clientDataOrNull.currentPage + 1;
            dispatch(getclientData({ pageSize: 10, page: nextPage }));
        }
    }

    const handlePrevPage = () => {
        const clientDataOrNull = clientData?.clientData;

        if (clientDataOrNull != null) {
            const prevPage = clientDataOrNull.currentPage - 1;
            dispatch(getclientData({ pageSize: 10, page: prevPage }));
        }
    }

    return (
        <div className="bodyPage">
            <div className="topSection">
                <div className="text">Clients Page</div>
                <div className="addNew" onClick={() => navigate('/client/new')}><FontAwesomeIcon size='lg' icon={faPlus} /></div>
            </div>

            {clientData?.clientData?.clients == null ?
                <TableSkeleton />
                :
                <SimpleTable data={clientData?.clientData?.clients} columns={columns} totalPages={clientData?.clientData?.totalPages} currentPage={clientData?.clientData?.currentPage} handleNextPage={handleNextPage} handlePrevPage={handlePrevPage} />
            }

            <ClientModal
                show={getModalShow}
                onHide={() => setModalShow(false)}
                data={clientCarsData}
            />
        </div>
    );
};
export default Client