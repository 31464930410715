import { useState } from 'react';
import "./../../../Style/pages/Form.scss";
import "./../../../Style/pages/Pages.scss";
import { useDispatch, useSelector } from 'react-redux';
import TodoApi from '../../../api/TodoApi';
import { getTodoData } from '../../../app/Slices/TodoDataSlice';

const NewTodo = ({ setDisplayedComponent }) => {
    const { CreateTodo } = TodoApi();
    const dispatch = useDispatch();
    const todos = useSelector((state) => state.todoData?.todos);

    const [clientData, setClientData] = useState({
        title: "",
        description: "",
        completed: false,
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setClientData({
            ...clientData,
            [name]: value
        });
    };

    const validate = () => {
        let validationErrors = {};
        if (!clientData.title) validationErrors.title = "Title is required";
        if (!clientData.description) validationErrors.description = "Description is required";

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate()) {
            CreateTodo({
                title: clientData.title,
                description: clientData.description,
            }).then((response) => {
                if (response === 201) {
                    setDisplayedComponent("DisplayTodo");
                    dispatch(getTodoData({ limit: 10, page: todos.currentPage, filter: todos.filterBy }));
                }
            });

        } else {
            console.log("Validation failed");
        }
    };

    return (
        <div className="formsection">
            <h1
                className={
                    Object.keys(errors).length > 0 &&
                        (errors.title || errors.description)
                        ? "danger"
                        : ""
                }>New Todo Form</h1>

            <label htmlFor="title">Title</label>
            <input
                type="text"
                id="title"
                name="title"
                placeholder="Title"
                value={clientData.title}
                onChange={handleChange}
            />
            {errors.title && <span className="error">{errors.title}</span>}

            <label htmlFor="description">Description</label>
            <textarea
                type="text"
                id="description"
                name="description"
                placeholder="Description"
                value={clientData.description}
                onChange={handleChange}
            />
            {errors.description && <span className="error">{errors.description}</span>}

            <div className="wrap">
                <button onClick={handleSubmit}>
                    Submit
                </button>
            </div>
        </div>
    );
};

export default NewTodo;
