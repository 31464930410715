import ContactAPI from "../../../../api/ContactApi";
import "./../../../../Style/SubComp/RateUs.scss";
import { useState, useEffect, useRef } from "react";

const RateComp = ({ rateData }) => {
    const { CreateRate, UpdateRate } = ContactAPI();
    const [count, setCount] = useState(rateData?.rate || 0);
    const [isHolding, setIsHolding] = useState(false);
    const [progress, setProgress] = useState(0);
    const [showMessageSection, setShowMessageSection] = useState(false);
    const [message, setMessage] = useState("");
    const intervalRef = useRef(null);

    // Handle hold action
    useEffect(() => {
        if (isHolding) {
            intervalRef.current = setInterval(() => {
                setCount(prev => Math.min(prev + 1, 10));
                setProgress(prev => Math.min(prev + 10, 100));
            }, 500);
        } else {
            clearInterval(intervalRef.current);
        }

        return () => clearInterval(intervalRef.current);
    }, [isHolding]);

    useEffect(() => {
        setCount(rateData?.rate);
    }, [rateData?.rate]);

    // Show message input for lower ratings
    useEffect(() => {
        setShowMessageSection(count < 6);
    }, [count]);

    // Reset on hold start
    const handleHoldStart = () => {
        setIsHolding(true);
        setCount(1);
        setProgress(0);
    };

    // Stop holding
    const handleHoldEnd = () => {
        setIsHolding(false);
    };

    // General event handlers for mouse/touch
    const handleStart = (e) => {
        handleHoldStart();
        e.preventDefault();
    };

    const handleEnd = (e) => {
        handleHoldEnd();
        e.preventDefault();
    };

    // Handle submission
    const handleSubmit = () => {
        const username = sessionStorage.getItem("username");
        const ratePayload = { username, rate: count, message: message };

        if (count < 6 && message === "") {
            alert("Please enter a message");
            return;
        }

        if (rateData?.rate === undefined) {
            CreateRate(ratePayload);
        } else {
            UpdateRate(ratePayload);
        }

        setMessage("");
    };

    // Get image based on count
    const getImageForCount = () => {
        const basePath = '/images/logo/';
        if (count <= 0 || count == undefined) return `${basePath}face0.png`;
        if (count <= 2) return `${basePath}face12.png`;
        if (count <= 4) return `${basePath}face34.png`;
        if (count <= 6) return `${basePath}face56.png`;
        if (count <= 8) return `${basePath}face78.png`;
        return `${basePath}face90.png`;
    };

    return (
        <div className="RateUs">
            <div className="support-link">Rate us</div>
            <div className="rating-display">{count == 0 ? "?" : count}</div>
            <img src={getImageForCount()} alt="rating face" className="rating-image" />
            <button
                className={`rate-button ${isHolding ? 'active' : ''}`}
                onMouseDown={handleStart}
                onMouseUp={handleEnd}
                onMouseLeave={handleEnd}
                onTouchStart={handleStart}
                onTouchEnd={handleEnd}
            >
                Press and Hold
                <div className="progress-bar">
                    <div className="progress" style={{ width: `${progress}%` }}></div>
                </div>
            </button>
            <div className="message-section">
                <textarea
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    placeholder={rateData?.message || "Please enter a message"}
                />
            </div>
            <button className="submit-button" onClick={handleSubmit}>
                Submit
            </button>


        </div>
    );
};

export default RateComp;
