import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./../../Style/components/ModalStyle.scss";
import { useState, useEffect } from 'react';
import TaskApi from '../../api/TaskApi';
import Field from '../Render/Field';

function TaskModal(props) {
    const { UpdateTask } = TaskApi();

    const [clientData, setClientData] = useState({
        issue: props?.data?.issue,
        items: props?.data?.items,
        price: props?.data?.price,
        note: props?.data?.note
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (props?.data) {
            setClientData({
                issue: props?.data?.issue,
                items: props?.data?.items,
                price: props?.data?.price,
                note: props?.data?.note
            });
        }
    }, [props?.data]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setClientData({
            ...clientData,
            [name]: value
        });
    };

    const validate = () => {
        let validationErrors = {};
        if (!clientData.issue) validationErrors.issue = "Hand Cost is required";
        if (!clientData.items) validationErrors.items = "items is required";
        if (!clientData.price) validationErrors.price = "price is required";
        if (!clientData.note) validationErrors.note = "Note is required";

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate()) {
            UpdateTask(props?.data?.id, clientData).then((response) => {
                if (response === 200) {
                    props.onHide();
                }
            });

        } else {
            console.log("Validation failed");
        }
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <div className='HeaderTitle'>
                        Edit Task
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="formsection">

                    {/* Reusing the Field component for each input */}
                    <Field
                        id="issue"
                        label="Issue"
                        type="text"
                        name="issue"
                        value={clientData.issue}
                        handleChange={handleChange}
                        error={errors.issue}
                        placeholder="Enter the issue"
                    />

                    <Field
                        id="items"
                        label="Items"
                        type="text"
                        name="items"
                        value={clientData.items}
                        handleChange={handleChange}
                        error={errors.items}
                        placeholder="Enter the items"
                    />

                    <Field
                        id="price"
                        label="Price"
                        type="text"
                        name="price"
                        value={clientData.price}
                        handleChange={handleChange}
                        error={errors.price}
                        placeholder="Enter the price"
                    />

                    <Field
                        id="note"
                        label="Note"
                        type="text"
                        name="note"
                        value={clientData.note}
                        handleChange={handleChange}
                        error={errors.note}
                        placeholder="Enter a note"
                    />

                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" className="wrap" onClick={handleSubmit}>Edit</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default TaskModal;