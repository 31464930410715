import "./../../Style/pages/Pages.scss";
import "./../../Style/pages/Component/UserProfile.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import RepairAPI from "../../api/RepairApi";
import RepairTask from "./Component/RepairTask";
import RepairModal from "../../components/Modals/RepairModal";
import { HandleLocalDate } from "../../utils/FormattingData";

const SelectedClientCarRepair = () => {
    const navigate = useNavigate();
    const { ClientId, CarId, RepairId } = useParams();
    const { GetRepairById } = RepairAPI();
    const [clientCarRepairData, setclientCarRepairData] = useState([]);
    const [getModalShow, setModalShow] = useState(false);
    useEffect(() => {
        GetRepairById(RepairId).then((res) => {
            setclientCarRepairData(res?.data?.repair)
        })
    }, [RepairId])
    return (
        <div className="bodyPage">
            <div className="topSection">
                <div className="text">Client Car Repair Page</div>
                <div className="addNew" onClick={() => window.history.back()}><FontAwesomeIcon size='lg' icon={faArrowLeft} /></div>
            </div>

            <div className="UserProfileSection">
                <div className="EditBtn" onClick={() => { setModalShow(true) }}><FontAwesomeIcon size='lg' icon={faPenToSquare} /></div>
                <div className="imageProfile">
                    <img src="/images/repairAvatar.png" alt="Logo" />
                </div>
                <div className="infoArea">
                    <div className="label">Note</div>
                    <div className="text">{clientCarRepairData?.note}</div>
                    <div className="label">Hand Cost</div>
                    <div className="text">{clientCarRepairData?.handCost}$</div>
                    <div className="label">Created</div>
                    <div className="text">{HandleLocalDate(clientCarRepairData?.createdAt)}</div>
                    <div className="label">Updated</div>
                    <div className="text">{HandleLocalDate(clientCarRepairData?.updatedAt)}</div>
                </div>
            </div>
            <RepairTask />

            <RepairModal
                show={getModalShow}
                onHide={() => setModalShow(false)}
                data={clientCarRepairData}
            />

        </div>
    )
}

export default SelectedClientCarRepair