import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./../../Style/components/ModalStyle.scss";
import { useState, useEffect } from 'react';
import RepairAPI from '../../api/RepairApi';
import Field from '../Render/Field';

function RepairModal(props) {
    const { UpdateRepair } = RepairAPI();

    const [clientData, setClientData] = useState({
        handCost: props?.data?.handCost,
        note: props?.data?.note
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (props?.data) {
            setClientData({
                handCost: props?.data?.handCost,
                note: props?.data?.note
            });
        }
    }, [props?.data]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setClientData({
            ...clientData,
            [name]: value
        });
    };

    const validate = () => {
        let validationErrors = {};
        if (!clientData.handCost) validationErrors.handCost = "Hand Cost is required";
        if (!clientData.note) validationErrors.note = "Note is required";

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate()) {
            UpdateRepair(props?.data?.id, clientData).then((response) => {
                if (response === 200) {
                    props.onHide();
                }
            });

        } else {
            console.log("Validation failed");
        }
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <div className='HeaderTitle'>
                        Edit Repair
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="formsection">
                    {/* Use Field component for handCost */}
                    <Field
                        id="handCost"
                        label="Hand Cost"
                        type="text"
                        name="handCost"
                        value={clientData.handCost}
                        handleChange={handleChange}
                        error={errors.handCost}
                        placeholder="Enter Hand Cost"
                    />

                    {/* Use Field component for note */}
                    <Field
                        id="note"
                        label="Note"
                        type="text"
                        name="note"
                        value={clientData.note}
                        handleChange={handleChange}
                        error={errors.note}
                        placeholder="Enter Note"
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" className="wrap" onClick={handleSubmit}>Edit</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default RepairModal;