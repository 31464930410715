import { toast } from "react-toastify";
import useInterceptorAxios from "../utils/axios";
import { useNavigate, useParams } from "react-router-dom";

const CarAPI = () => {
    const Client = useInterceptorAxios();
    const navigate = useNavigate();
    const { ClientId, CarId } = useParams();

    const CreateCar = async (formData) => {
        try {
            const response = await Client.post(`/car`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response?.status === 201) {
                toast.success(response?.data?.message);
            }

            return response?.status;
        } catch (e) {
            toast.error("Something went wrong while creating a new car!!");
        }
    };


    const GetCarById = async (id) => {
        try {
            const response = await Client.get(`/car/${id}`);
            return response;
        } catch (e) {
            toast.error("Something went wrong while Getting Car info !!");
        }
    };

    const GetCarsByUserId = async (id) => {
        try {
            const response = await Client.get(`/car/user/${id}`);
            return response;
        } catch (e) {
            toast.error("Something went wrong while Getting Car info !!");
        }
    };

    const UpdateCar = async (id, newCarData) => {
        try {
            const response = await Client.put(`/car/${id}`, {
                plate: newCarData?.plate,
                model: newCarData?.model,
                brand: newCarData?.brand,
                year: newCarData?.year,
                color: newCarData?.color,
                engine: newCarData?.engine,
                client_id: newCarData?.client_id, // Adjusted for consistency
            });
            if (response?.status === 200) {
                toast.success(response?.data?.message);
                navigate(0); // Reload or navigate after success
            }

            return response?.status;
        } catch (e) {
            toast.error("Something went wrong while Updating Car!!");
        }
    };

    return {
        CreateCar,
        GetCarById,
        UpdateCar,
        GetCarsByUserId,
    };
};

export default CarAPI;
