import React, { useState } from "react";
import "./../Style/pages/LoginPage.scss";
import LoginAPI from "../api/LoginAPI";
import { useNavigate } from "react-router-dom";

function LoginPage() {
  const navigate = useNavigate();
  const { Register, Login } = LoginAPI();
  const [isSignUp, setIsSignUp] = useState(false);
  const [registrationData, setRegistrationData] = useState({
    username: "",
    password: "",
  });
  const spanArray = Array.from({ length: 50 }, (_, i) => i);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRegistrationData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isSignUp) {
      Register({ registrationData }).then((data) => {
        if (data === 201) {
          setIsSignUp(false);
        }
      });
    } else {
      Login({ registrationData });
    }
  };

  const toggleMode = () => {
    setIsSignUp((prevMode) => !prevMode);
  };

  return (
    <div className="LoginPage">
      <div className="container">
        <div className={`login-box ${isSignUp ? "sign-up" : "sign-in"}`}>
          <h2>{isSignUp ? "Sign Up" : "Login"}</h2>
          <form onSubmit={handleSubmit}>
            <div className="input-box">
              <label>Username</label>
              <input type="text" name="username" value={registrationData.username} onChange={handleInputChange} required />
            </div>
            <div className="input-box">
              <label>Password</label>
              <input type="password" name="password" value={registrationData.password} onChange={handleInputChange} required />
            </div>
            <button type="submit" className="btn">
              {isSignUp ? "Sign Up" : "Login"}
            </button>
            <div className="signup-link">
              <a href="#" onClick={toggleMode}>
                {isSignUp ? "Sign In" : "Sign Up"}
              </a>
            </div>
          </form>
        </div>
        {spanArray.map((_, i) => (
          <span key={i} style={{ "--i": i }}></span>
        ))}
      </div>
    </div>
  );
}

export default LoginPage;
