import ContactUsForm from "./Sections/ContactUsForm"
import LocateUs from "./Sections/LocateUs"
import RateUs from "./Sections/RateUs"
import SocialMedia from "./Sections/SocialMedia"

const UserContact = () => {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                    <LocateUs />
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                    <SocialMedia />
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                    <ContactUsForm />
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                    <RateUs />
                </div>

            </div>
        </div>
    )
}

export default UserContact