import { useDispatch, useSelector } from "react-redux";
import { getTodoData, settingTodoData } from "../../../app/Slices/TodoDataSlice";
import { useEffect } from "react";
import "./../../../Style/pages/Todo.scss";


const TodoFilter = () => {
    const dispatch = useDispatch();
    const todos = useSelector((state) => state.todoData?.todos);
    useEffect(() => {
        dispatch(getTodoData({ limit: 10, page: 1, filter: 'day' }));
        dispatch(settingTodoData({ field: 'filterBy', value: 'day' }));
    }, [])

    const handleFilter = (filtered) => {
        dispatch(getTodoData({ limit: 10, page: 1, filter: filtered }))
        dispatch(settingTodoData({ field: 'filterBy', value: filtered }));
    }
    const handleNextPage = () => {
        const TodoDataOrNull = todos;

        if (TodoDataOrNull != null) {
            const nextPage = TodoDataOrNull.currentPage + 1;
            dispatch(getTodoData({ limit: 10, page: nextPage, filter: todos?.filterBy }));
        }
    }

    const handlePrevPage = () => {
        const TodoDataOrNull = todos;

        if (TodoDataOrNull != null) {
            const prevPage = TodoDataOrNull.currentPage - 1;
            dispatch(getTodoData({ limit: 10, page: prevPage, filter: todos?.filterBy }));
        }
    }
    return (
        <div className="TodoFilter">
            <div className="filter">
                <button className={todos?.filterBy === 'day' ? 'active' : 'notActive'} onClick={() => handleFilter('day')}>Day</button>
                <button className={todos?.filterBy === 'week' ? 'active' : 'notActive'} onClick={() => handleFilter('week')}>Week</button>
                <button className={todos?.filterBy === 'month' ? 'active' : 'notActive'} onClick={() => handleFilter('month')}>Month</button>
                <button className={todos?.filterBy === 'all' ? 'active' : 'notActive'} onClick={() => handleFilter('all')}>All</button>
            </div>

            {todos?.totalPages > 1 && <div className='pagination'>
                <div className='pages'>
                    Page <strong>{todos?.currentPage} of {todos?.totalPages}</strong>
                </div>

                <div className='whereToGo'>
                    <button onClick={() => { handlePrevPage(); }} disabled={todos?.currentPage === 1}>Previous</button>
                    <button onClick={() => { handleNextPage(); }} disabled={todos?.currentPage === todos?.totalPages}>Next</button>
                </div>
            </div>}
        </div>
    );
};
export default TodoFilter;