import { configureStore } from "@reduxjs/toolkit";
import TodoDataSlice from "./Slices/TodoDataSlice";
import StorageSlice from "./Slices/StorageSlice";
import ClientSlice from "./Slices/ClientSlice";
import SelectedClientDataSlice from "./Slices/SelectedClientDataSlice";
import SelectedCarDataSlice from "./Slices/SelectedCarDataSlice";
import SelectedRepairDataSlice from "./Slices/SelectedRepairDataSlice";
import UserStoarageSlices from "./Slices/UserStoarageSlices";
import MenuSlice from "./Slices/MenuSlice";
import FullMenuSlice from "./Slices/FullMenuSlice";

const store = configureStore({
  reducer: {
    todoData: TodoDataSlice,
    storage: StorageSlice,
    clientData: ClientSlice,
    selectedClientData: SelectedClientDataSlice,
    selectedCarData: SelectedCarDataSlice,
    selectedRepairData: SelectedRepairDataSlice,
    userStorageData: UserStoarageSlices,
    menuData: MenuSlice,
    fullMenuData: FullMenuSlice
  },
});

export default store;
