import axios from "axios";
import { config } from "./Constants";
import { useNavigate } from "react-router-dom";

const URL = config.url;

const useInterceptorAxios = () => {
  const navigate = useNavigate();

  const InterceptorClient = axios.create({
    baseURL: URL,
    headers: {
      Authorization: sessionStorage.getItem("Token"),
    },
  });

  InterceptorClient.interceptors.request.use(
    (config) => {
      if (config.data instanceof FormData) {
        config.headers['Content-Type'] = 'multipart/form-data';
      } else {
        config.headers['Content-Type'] = 'application/json';
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  InterceptorClient.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        sessionStorage.clear();
        localStorage.clear();
        navigate("/");
      }
      return Promise.reject(error);
    }
  );

  return InterceptorClient;
};

export default useInterceptorAxios;
