import { faStar } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import RateComp from "./Component/RateComp";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUserRate } from "../../../app/Slices/UserStoarageSlices";

const RateUs = () => {
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.userStorageData);

    useEffect(() => {
        if (userData?.userRate === null) {
            dispatch(getUserRate(sessionStorage.getItem("username")));
        }
    }, []);

    return (
        <div className="CardSection">
            <div className="cardHeader">
                <div className="iconTitle">
                    <div className="icons"><FontAwesomeIcon size="lg" icon={faStar} /></div>
                    <div className="title">Rate Us</div>
                </div>
            </div>
            <div className="cardBody">
                <RateComp rateData={userData?.userRate?.rate} />
            </div>

            <div className="cardFooter">

            </div>
        </div>
    );
};

export default RateUs;
