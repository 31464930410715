import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import "./../../Style/pages/Form.scss";
import "./../../Style/pages/Pages.scss";
import ClientAPI from "../../api/ClientApi";
import LoginAPI from "../../api/LoginAPI";
import { useDispatch } from "react-redux";
import { getclientData } from "../../app/Slices/ClientSlice";
import Field from "../../components/Render/Field";
import ImageUpload from "../../components/Render/ImageUpload"; // Import ImageUpload component

const NewClient = () => {
    const { CreateClient } = ClientAPI();
    const { Register } = LoginAPI();
    const dispatch = useDispatch();

    const [clientData, setClientData] = useState({
        fName: "",
        lName: "",
        gender: "",
        phoneNb: "",
        email: "",
        address: "",
        username: "",
        password: "",
        confirmPassword: ""
    });

    const [image, setImage] = useState(null); // State for image
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setClientData({
            ...clientData,
            [name]: value
        });
    };

    const handleImageChange = (e) => {
        setImage(e.target.files[0]); // Set selected image
    };

    const validate = () => {
        let validationErrors = {};
        if (!clientData.fName) validationErrors.fName = "First Name is required";
        if (!clientData.lName) validationErrors.lName = "Last Name is required";
        if (!clientData.phoneNb) validationErrors.phoneNb = "Phone Number is required";
        if (!clientData.email) {
            validationErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(clientData.email)) {
            validationErrors.email = "Email is invalid";
        }
        if (!clientData.address) validationErrors.address = "Address is required";
        if (!clientData.username) validationErrors.username = "Username is required";
        if (!clientData.password) validationErrors.password = "Password is required";
        if (!clientData.confirmPassword) validationErrors.confirmPassword = "Confirm Password is required";
        if (clientData.password !== clientData.confirmPassword) validationErrors.confirmPassword = "Passwords do not match";
        if (!image) validationErrors.image = "Profile image is required";

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate()) {
            const formData = new FormData();
            formData.append("fName", clientData.fName);
            formData.append("lName", clientData.lName);
            formData.append("phoneNb", clientData.phoneNb);
            formData.append("email", clientData.email);
            formData.append("address", clientData.address);
            formData.append("username", clientData.username);
            formData.append("image", image); // Append image to FormData

            CreateClient(formData).then((response) => {
                if (response === 201) {
                    Register({
                        username: clientData.username,
                        password: clientData.password
                    });
                    dispatch(getclientData({ pageSize: 10, page: 1 }));
                }
            });
        } else {
            console.log("Validation failed");
        }
    };

    return (
        <div className="bodyPage">
            <div className="topSection">
                <div className="text">New Client Page</div>
                <div className="addNew" onClick={() => { window.history.back() }}>
                    <FontAwesomeIcon size='lg' icon={faMinus} />
                </div>
            </div>
            <div className="formsection">
                <h1 className={Object.keys(errors).length > 0 ? "danger" : ""}>Client Form</h1>

                <Field
                    id="fName"
                    label="First Name"
                    type="text"
                    name="fName"
                    placeholder="First Name"
                    value={clientData.fName}
                    handleChange={handleChange}
                    error={errors.fName}
                />

                <Field
                    id="lName"
                    label="Last Name"
                    type="text"
                    name="lName"
                    placeholder="Last Name"
                    value={clientData.lName}
                    handleChange={handleChange}
                    error={errors.lName}
                />

                <Field
                    id="phoneNb"
                    label="Phone Number"
                    type="number"
                    name="phoneNb"
                    placeholder="Phone Number"
                    value={clientData.phoneNb}
                    handleChange={handleChange}
                    error={errors.phoneNb}
                />

                <Field
                    id="email"
                    label="Email"
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={clientData.email}
                    handleChange={handleChange}
                    error={errors.email}
                />

                <Field
                    id="address"
                    label="Address"
                    type="text"
                    name="address"
                    placeholder="Address"
                    value={clientData.address}
                    handleChange={handleChange}
                    error={errors.address}
                />

                <ImageUpload
                    handleImageChange={handleImageChange} // Pass image change handler
                    error={errors.image} // Display error if image is missing
                />

                <div className="divider"></div>
                <h3 className={Object.keys(errors).length > 0 ? "danger" : ""}>Credentials</h3>

                <Field
                    id="username"
                    label="Username"
                    type="text"
                    name="username"
                    placeholder="Username"
                    value={clientData.username}
                    handleChange={handleChange}
                    error={errors.username}
                />

                <Field
                    id="password"
                    label="Password"
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={clientData.password}
                    handleChange={handleChange}
                    error={errors.password}
                />

                <Field
                    id="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    value={clientData.confirmPassword}
                    handleChange={handleChange}
                    error={errors.confirmPassword}
                />

                <div className="wrap">
                    <button onClick={handleSubmit}>Submit</button>
                </div>
            </div>
        </div>
    );
};

export default NewClient;
