// import { faAddressCard, faUser } from "@fortawesome/free-solid-svg-icons"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import "./../../../Style/components/carouselSlider.scss";

// const SliderSection = () => {

//     return (
//         <div className="CardSection">
//             <div className="cardHeader">
//                 <div className="iconTitle">
//                     <div className="icons"><FontAwesomeIcon size="lg" icon={faAddressCard} /></div>
//                     <div className="title">SlideShow</div>
//                 </div>
//             </div>
//             <div id="cardBody">
//                 <div class="carousel">
//                     <div class="wrapper">
//                         <img src="/images/Slider/Slider1.jpg" />
//                         <img src="/images/Slider/Slider2.jpg" />
//                         <img src="/images/Slider/Slider3.jpg" />
//                         <img src="/images/Slider/Slider4.jpg" />
//                         <img src="/images/Slider/Slider5.jpg" />
//                         <img src="/images/Slider/Slider6.jpg" />
//                         <img src="/images/Slider/Slider7.jpg" />
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )

// }

// export default SliderSection
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./../../../Style/components/carouselSlider.scss";
import { useState, useEffect, useRef } from "react";

const SliderSection = () => {
    const images = [
        "/images/Slider/Slider1.jpg",
        "/images/Slider/Slider2.jpg",
        "/images/Slider/Slider3.jpg",
        "/images/Slider/Slider4.jpg",
        "/images/Slider/Slider5.jpg",
        "/images/Slider/Slider6.jpg",
        "/images/Slider/Slider7.jpg",
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const intervalRef = useRef(null);

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            setCurrentIndex((prevIndex) =>
                prevIndex === images.length - 1 ? 0 : prevIndex + 1
            );
        }, 3000);

        return () => clearInterval(intervalRef.current);
    }, [images]);

    return (
        <div className="CardSlideSection">

            <div className="carousel">
                <div
                    className="wrapper"
                    style={{
                        transform: `translateX(-${currentIndex * 100}%)`,
                    }}
                >
                    {images.map((src, index) => (
                        <img key={index} src={src} alt={`Slider ${index}`} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SliderSection;
