import React, { useState } from "react";
import "./../../Style/pages/Pages.scss";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TodoBody from "./Component/TodoBody";
import NewTodo from "./Component/NewTodo";
import TodoFilter from "./Component/TodoFilter";

function ToDo() {
  const [getDisplayedComponent, setDisplayedComponent] = useState("DisplayTodo");
  return (
    <>
      <div className="bodyPage">
        <div className="topSection">
          <div className="text">To Do Page</div>
          <div className="addNew" onClick={() => setDisplayedComponent(getDisplayedComponent === "DisplayTodo" ? "DisplayNewTodo" : "DisplayTodo")}>
            <FontAwesomeIcon size="lg" icon={getDisplayedComponent === "DisplayTodo" ? faPlus : faMinus} />
          </div>
        </div>

        {getDisplayedComponent === "DisplayNewTodo" && <NewTodo setDisplayedComponent={setDisplayedComponent} />}
        <TodoFilter />

        <TodoBody />
      </div>
    </>
  );
}
export default ToDo;
