import { toast } from "react-toastify";
import useInterceptorAxios from "../utils/axios";
import { useNavigate, useParams } from "react-router-dom";

const MenuApi = () => {
    const Client = useInterceptorAxios();
    const navigate = useNavigate();

    const CreateCategory = async (formData) => {
        try {
            const response = await Client.post(`/menu-category`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response?.status === 201) {
                toast.success(response?.data?.message);
                // navigate(0);
            }

            return response?.status;
        } catch (e) {
            toast.error("Something went wrong while Creating New Category!!");
        }
    };

    const GetCategoryById = async (id) => {
        try {
            const response = await Client.get(`/menu-category/${id}`);
            return response;
        } catch (e) {
            toast.error("Something went wrong while Getting Category info !!");
        }
    };

    const UpdateCategory = async (id, formData) => {
        try {
            const response = await Client.put(`/menu-category/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data' // Set the header to handle file uploads
                }
            });

            if (response?.status === 200) {
                toast.success(response?.data?.message);
                navigate(0);
            }

            return response?.status;
        } catch (e) {
            toast.error("Something went wrong while updating the category!");
            console.error(e);
        }
    };


    const CreateItem = async (newItemData) => {
        try {
            const response = await Client.post(`/menu-item`, {
                title: newItemData?.title,
                description: newItemData?.description,
                price: newItemData?.price,
                isShow: newItemData?.isShow,
                priority: newItemData?.priority,
                category_id: newItemData?.category_id,
            });
            if (response?.status === 201) {
                toast.success(response?.data?.message);
            }

            return response?.status;
        } catch (e) {
            toast.error("Something went wrong while Creating New Item!!");
        }
    };

    const UpdateItem = async (id, newItemData) => {
        try {
            const response = await Client.put(`/menu-item/${id}`, {
                title: newItemData?.title,
                description: newItemData?.description,
                price: newItemData?.price,
                isShow: newItemData?.isShow,
                priority: newItemData?.priority,
                category_id: newItemData?.category_id,
            });
            if (response?.status === 200) {
                toast.success(response?.data?.message);
                navigate(0);
            }

            return response?.status;
        } catch (e) {
            toast.error("Something went wrong while Updating Item !!");
        }
    };



    return {
        CreateCategory,
        GetCategoryById,
        UpdateCategory,
        CreateItem,
        UpdateItem
    };
};

export default MenuApi;
