import { toast } from "react-toastify";
import useInterceptorAxios from "../utils/axios";
import { useNavigate, useParams } from "react-router-dom";

const ContactAPI = () => {
    const Client = useInterceptorAxios();
    const navigate = useNavigate();

    const CreateRate = async (newRateData) => {
        try {
            const response = await Client.post(`/rate`, {
                username: newRateData?.username,
                rate: newRateData?.rate,
                message: newRateData?.message,
            });
            if (response?.status === 201) {
                toast.success(response?.data?.message);
            }

            return response?.status;
        } catch (e) {
            toast.error("Something went wrong while Creating New Client Rate!!");
        }
    };

    const UpdateRate = async (newRateData) => {
        try {
            const response = await Client.put(`/rate`, {
                username: newRateData?.username,
                rate: newRateData?.rate,
                message: newRateData?.message,
            });
            if (response?.status === 200) {
                toast.success(response?.data?.message);
                console.log(response?.data?.message);
                window.location.reload();
            }

            return response?.status;
        } catch (e) {
            toast.error("Something went wrong while Updating Rate !!");
        }
    };


    return {
        CreateRate,
        UpdateRate,
    };
};

export default ContactAPI;
