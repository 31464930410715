import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { jwtDecode } from 'jwt-decode';

const RouteGuard = ({ children, requiredRole }) => {
  const [decodedToken, setDecodedToken] = useState(null);

  useEffect(() => {
    const token = sessionStorage.getItem("Token");
    if (token) {
      const decoded = jwtDecode(token);
      setDecodedToken(decoded);
    }
  }, []);

  if (!decodedToken) {
    return <>
      <div>Loading...</div>
      <button onClick={() => window.location.href = "/"}>Return To Login</button>
    </>;
  }

  // if (!decodedToken.role) {
  //   return <Navigate to="/todo" />;
  // }

  if (decodedToken.role !== requiredRole) {
    return <Navigate to="/not-authorized" />;
  }

  return children;
};

export default RouteGuard;
