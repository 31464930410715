import { useState } from "react";
import InternalNavbar from "../../components/BodyContent/InternalNavbar";
import { faHome, faCog, faTachometerAlt, faUser, faDatabase } from '@fortawesome/free-solid-svg-icons'; // Import icons from FontAwesome
import StaticDataScreen from "./Component/StaticDataScreen";

const ContolPanel = () => {
    const screens = [
        { label: "Home", link: "#home", icon: faHome },
        { label: "Dashboard", link: "#dashboard", icon: faTachometerAlt },
        { label: "StaticData", link: "#StaticData", icon: faDatabase },
        { label: "Profile", link: "#profile", icon: faUser },
    ];
    const [activeScreen, setActiveScreen] = useState(screens[0].label); // Default to first screen

    return (
        <>
            <div className="bodyPage">
                <div className="topSection">
                    <div className="text">Control Panel Page</div>
                </div>
                <InternalNavbar screens={screens} setActiveScreen={setActiveScreen} activeScreen={activeScreen} />

                <div className="bodyContent">
                    {activeScreen === "Home" && <h1>Home</h1>}
                    {activeScreen === "Dashboard" && <h1>Dashboard</h1>}
                    {activeScreen === "StaticData" && <StaticDataScreen />}
                    {activeScreen === "Profile" && <h1>Profile</h1>}
                </div>
            </div>
        </>
    );
};

export default ContolPanel;
