import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { config } from "./../../utils/Constants";
import AxiosRedux from "./../../utils/AxiosRedux";
const URL = config.url;

const initialState = {
    isLoading: false,
    clientData: null,
};

export const getclientData = createAsyncThunk(
    'clientData/getclientData',
    async ({ pageSize, page }, { dispatch }) => {
        try {
            const response = await AxiosRedux(`${URL}/clients?pageSize=${pageSize}&page=${page}`, 'GET');

            if (response && response.status === 200) {
                const clientData = response.data;

                // Dispatch the data to the appropriate slice based on the status
                dispatch(setclientDataAction({ data: clientData }));
                return clientData;
            } else {
                toast.error('Something went wrong while Getting Clients Data');
                return false;
            }
        } catch (error) {
            toast.error('Something went wrong while Getting Clients Data');
            return false;
        }
    }
);

// Action to set Clients data based on status
const clientDataSlice = createSlice({
    name: "clientData",
    initialState,
    reducers: {
        setclientDataAction: (state, action) => {
            const { data } = action.payload;
            state.clientData = data;
            state.clientData.currentPage=data.currentPage;
            state.clientData.totalItems=data.totalItems;
            state.clientData.totalPages=data.totalPages;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getclientData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(
                getclientData.fulfilled,
                (state) => {
                    state.isLoading = false;
                }
            )
            .addCase(getclientData.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

// Export the reducer and action with a different name
export const { setclientDataAction } = clientDataSlice.actions;
export default clientDataSlice.reducer;
