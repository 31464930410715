import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./../../Style/components/ModalStyle.scss";
import { useState, useEffect } from 'react';
import ClientAPI from '../../api/ClientApi';
import Field from '../Render/Field';
import ImageUpload from '../Render/ImageUpload';  // Import ImageUpload

function ClientModal(props) {
    const { UpdateClient } = ClientAPI();

    const [clientData, setClientData] = useState({
        fName: props?.data?.fName || '',
        lName: props?.data?.lName || '',
        phoneNb: props?.data?.phoneNb || '',
        email: props?.data?.email || '',
        address: props?.data?.address || '',
        username: props?.data?.username || '',
        image_url: props?.data?.image_url || ''  // Add image_url to state
    });

    const [imageFile, setImageFile] = useState(null);  // State for image file
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (props?.data) {
            setClientData({
                fName: props?.data?.fName || '',
                lName: props?.data?.lName || '',
                phoneNb: props?.data?.phoneNb || '',
                email: props?.data?.email || '',
                address: props?.data?.address || '',
                username: props?.data?.username || '',
                image_url: props?.data?.image_url || ''  // Ensure image_url is part of state
            });
        }
    }, [props?.data]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setClientData({
            ...clientData,
            [name]: value
        });
    };

    const handleImageChange = (e) => {
        setImageFile(e.target.files[0]);  // Handle image file selection
    };

    const validate = () => {
        let validationErrors = {};
        if (!clientData.fName) validationErrors.fName = "First Name is required";
        if (!clientData.lName) validationErrors.lName = "Last Name is required";
        if (!clientData.phoneNb) validationErrors.phoneNb = "Phone Number is required";
        if (!clientData.email) {
            validationErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(clientData.email)) {
            validationErrors.email = "Email is invalid";
        }
        if (!clientData.address) validationErrors.address = "Address is required";
        if (!clientData.username) validationErrors.username = "Username is required";

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate()) {
            const formData = new FormData();
            formData.append('fName', clientData.fName);
            formData.append('lName', clientData.lName);
            formData.append('phoneNb', clientData.phoneNb);
            formData.append('email', clientData.email);
            formData.append('address', clientData.address);
            formData.append('username', clientData.username);

            if (imageFile) {
                formData.append('image', imageFile);  // Append the image file to FormData
            }

            try {
                const response = await UpdateClient(props?.data?.id, formData);
                if (response === 200) {
                    props.onHide();
                }
            } catch (error) {
                console.error("Update failed:", error);
            }
        } else {
            console.log("Validation failed");
        }
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <div className='HeaderTitle'>
                        Edit Client
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="formsection">
                    <Field
                        id="fName"
                        label="First Name"
                        type="text"
                        name="fName"
                        value={clientData.fName}
                        handleChange={handleChange}
                        error={errors.fName}
                        placeholder="First Name"
                    />

                    <Field
                        id="lName"
                        label="Last Name"
                        type="text"
                        name="lName"
                        value={clientData.lName}
                        handleChange={handleChange}
                        error={errors.lName}
                        placeholder="Last Name"
                    />

                    <Field
                        id="phoneNb"
                        label="Phone Number"
                        type="text"
                        name="phoneNb"
                        value={clientData.phoneNb}
                        handleChange={handleChange}
                        error={errors.phoneNb}
                        placeholder="Phone Number"
                    />

                    <Field
                        id="email"
                        label="Email"
                        type="email"
                        name="email"
                        value={clientData.email}
                        handleChange={handleChange}
                        error={errors.email}
                        placeholder="Email"
                    />

                    <Field
                        id="address"
                        label="Address"
                        type="text"
                        name="address"
                        value={clientData.address}
                        handleChange={handleChange}
                        error={errors.address}
                        placeholder="Address"
                    />

                    <Field
                        id="username"
                        label="Username"
                        type="text"
                        name="username"
                        value={clientData.username}
                        handleChange={handleChange}
                        error={errors.username}
                        placeholder="Username"
                    />

                    {/* Image Upload Field */}
                    <ImageUpload
                        handleImageChange={handleImageChange}
                        error={errors.image}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" className="wrap" onClick={handleSubmit}>Edit</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ClientModal;
