import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./../../Style/components/ModalStyle.scss";
import TodoApi from '../../api/TodoApi';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { getTodoData } from '../../app/Slices/TodoDataSlice';

function TodoModal(props) {
    const { UpdateTodo } = TodoApi();
    const dispatch = useDispatch();
    const todos = useSelector((state) => state.todoData?.todos);

    const [clientData, setClientData] = useState({
        title: props?.data?.title || "",
        description: props?.data?.description || "",
        completed: props?.data?.completed || false,
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (props?.data) {
            setClientData({
                title: props?.data?.title || "",
                description: props?.data?.description || "",
                completed: props?.data?.completed || false,
            });
        }
    }, [props?.data]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setClientData({
            ...clientData,
            [name]: value
        });
    };

    const validate = () => {
        let validationErrors = {};
        if (!clientData.title) validationErrors.title = "Title is required";
        if (!clientData.description) validationErrors.description = "Description is required";

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate()) {
            UpdateTodo(props?.data?.id, clientData, false).then((response) => {
                if (response === 200) {
                    dispatch(getTodoData({ limit: 10, page: todos.currentPage, filter: todos.filterBy }));
                    props.onHide();
                }
            });

        } else {
            console.log("Validation failed");
        }
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <div className='HeaderTitle'>
                        Edit Todo
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="formsection">
                    <label htmlFor="title">Title</label>
                    <input
                        type="text"
                        id="title"
                        name="title"
                        placeholder="Enter title"
                        value={clientData.title || props?.data?.title || ""}
                        onChange={handleChange}
                    />
                    {errors.title && <span className="error">{errors.title}</span>}

                    <label htmlFor="description">Description</label>
                    <input
                        type="text"
                        id="description"
                        name="description"
                        placeholder="Enter description"
                        value={clientData.description || props?.data?.description || ""}
                        onChange={handleChange}
                    />
                    {errors.description && <span className="error">{errors.description}</span>}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" className="wrap" onClick={handleSubmit}>Edit</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default TodoModal;
