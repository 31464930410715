
import "./../../Style/UserCommon.scss";
import MenuSection from "./Sections/MenuSection";
import OwerServicesSection from "./Sections/OwerServices";
import ProfileSection from "./Sections/ProfileSection";
import SliderSection from "./Sections/SliderSection";

const UserHome = () => {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                    <SliderSection />
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                    <ProfileSection />
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                    <MenuSection />
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                    <OwerServicesSection />
                </div>

            </div>
        </div>
    )
}

export default UserHome