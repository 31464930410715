import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { getCaRepairsData } from "./../../../app/Slices/SelectedCarDataSlice"
import SimpleTable from "../../../components/BodyContent/SimpleTable";
import TableSkeleton from "../../../components/BodyContent/TableSkeleton";
import { useNavigate, useParams } from 'react-router-dom';
import { faArrowRight, faMinus, faPenToSquare, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NewRepair from "./NewRepair";
import RepairModal from "../../../components/Modals/RepairModal";

const CarRepair = () => {
    const { ClientId, CarId, RepairId } = useParams();
    const CaRepairsData = useSelector(state => state.selectedCarData);
    const [getDisplayedComponent, setDisplayedComponent] = useState("CarRepair");
    const [getSelectedCarData, setclientCarsData] = useState({});
    const [getModalShow, setModalShow] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(getCaRepairsData({ id: CarId }));
    }, [])

    const handleClientSelected = (id) => {
        navigate(`/client/${ClientId}/car/${CarId}/repair/${id}`);
    }

    const columns = [
        { Header: 'ID', accessor: 'id', Cell: ({ value }) => <div>#{value}</div> },
        { Header: 'Note', accessor: 'note' },
        { Header: 'Hand Cost', accessor: 'handCost', Cell: ({ value }) => <div>${value}</div> },
        {
            Header: 'Action',
            accessor: 'action', // Use a unique accessor here
            Cell: ({ value, row }) => (
                <div className="actionBar">
                    <div className="EditBtn" onClick={() => { setModalShow(true); setclientCarsData(row.original) }}><FontAwesomeIcon size='lg' icon={faPenToSquare} /></div>
                    <button className="GreenBtn" onClick={() => { handleClientSelected(row.original.id) }}><FontAwesomeIcon size='lg' icon={faArrowRight} /></button>
                </div>
            )
        },
    ]

    return (
        <div className="ClientCar">
            <div className="topSection2">
                <div className="text">{getDisplayedComponent == "AddCarRepair" ? "Add Repair" : "Repairs"}</div>
                <div className="addNew" onClick={() => setDisplayedComponent(getDisplayedComponent == "AddCarRepair" ? "CarRepair" : "AddCarRepair")}><FontAwesomeIcon size='lg' icon={getDisplayedComponent == "AddCarRepair" ? faMinus : faPlus} /></div>
            </div>
            {getDisplayedComponent == "AddCarRepair" ?
                <NewRepair carId={CarId} setDisplayedComponent={setDisplayedComponent} /> :
                <>
                    {CaRepairsData?.CarRepairs?.repair == null ?
                        <TableSkeleton />
                        :
                        <SimpleTable data={CaRepairsData?.CarRepairs?.repair} columns={columns} totalPages={1} currentPage={1} handleNextPage={() => { }} handlePrevPage={() => { }} />
                    }
                </>
            }
            <RepairModal
                show={getModalShow}
                onHide={() => setModalShow(false)}
                data={getSelectedCarData}
            />
        </div>
    )
}

export default CarRepair