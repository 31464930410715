import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import SimpleTable from "../../../components/BodyContent/SimpleTable";
import TableSkeleton from "../../../components/BodyContent/TableSkeleton";
import { useParams } from 'react-router-dom';
import { faMinus, faPenToSquare, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getmenuCategoryItemSlice } from "../../../app/Slices/MenuSlice";
import NewItem from "./NewItem";
import ItemMenuModal from "../../../components/Modals/ItemMenuModal";

const CategoryItem = () => {
    const { CategoryId } = useParams();
    const MenuData = useSelector(state => state.menuData);
    const [getDisplayedComponent, setDisplayedComponent] = useState("ClientCar");
    const [getModalShow, setModalShow] = useState(false);
    const [getItemsMenuData, setItemsMenuData] = useState({});

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getmenuCategoryItemSlice({ categoryId: CategoryId }));
    }, [])


    const columns = [
        { Header: 'ID', accessor: 'id', Cell: ({ value }) => <div>#{value}</div> },
        { Header: 'Title', accessor: 'title' },
        { Header: 'Description', accessor: 'description' },
        { Header: 'Price', accessor: 'price' },
        { Header: 'Is Show', accessor: 'isShow', Cell: ({ value }) => <div>{value ? "Yes" : "No"}</div> },
        { Header: 'Priority', accessor: 'priority' },
        {
            Header: 'Action',
            accessor: 'action', // Use a unique accessor here
            Cell: ({ value, row }) => (
                <div className="actionBar">
                    <div className="EditBtn" onClick={() => { setModalShow(true); setItemsMenuData(row.original) }}><FontAwesomeIcon size='lg' icon={faPenToSquare} /></div>
                </div>
            )
        },
    ]



    return (
        <div className="ClientCar">
            <div className="topSection2">
                <div className="text">{getDisplayedComponent == "AddNewItem" ? "Add Item" : "Items"}</div>
                <div className="addNew" onClick={() => setDisplayedComponent(getDisplayedComponent == "AddNewItem" ? "CategoryItem" : "AddNewItem")}><FontAwesomeIcon size='lg' icon={getDisplayedComponent == "AddNewItem" ? faMinus : faPlus} /></div>
            </div>
            {getDisplayedComponent == "AddNewItem" ?
                <NewItem clientId={CategoryId} setDisplayedComponent={setDisplayedComponent} /> :
                <>
                    {MenuData?.categoryItemsData?.items == null ?
                        <TableSkeleton />
                        :
                        <SimpleTable data={MenuData?.categoryItemsData?.items} columns={columns} totalPages={1} currentPage={1} handleNextPage={() => { }} handlePrevPage={() => { }} />
                    }
                </>
            }
            <ItemMenuModal
                show={getModalShow}
                onHide={() => setModalShow(false)}
                data={getItemsMenuData}
            />
        </div>
    )
}

export default CategoryItem